import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { AgencyApiService } from './agency-api';
import { Router } from '@angular/router';
import { GeneralServiceMessages } from '../messages-services/utilities-message';

@Injectable({
    providedIn: 'root'
})
export class AgencyManagerService {
    public loading = false;
    public token = '';

    constructor(
        private agencyService: AgencyApiService,
        private router: Router,
        private messageSweet: GeneralServiceMessages
    ) {
        this.token = sessionStorage.getItem('data') || '';
    }
    getAgencyOne() {
        return new Promise<any[]>((resolve, reject) => {
            this.loading = true;
            this.agencyService.getOneAgency(this.token).subscribe(response => {
                this.loading = false;
                resolve(response.data);
            },
                error => {
                    this.loading = false;
                    this.validadorResponse(error);
                    reject([]);
                }
            );
        });
    }
    getAgency() {
        return new Promise<any[]>((resolve, reject) => {
            this.loading = true;
            this.agencyService.getAgency(this.token).subscribe(response => {
                this.loading = false;
                resolve(response.data);
            },
                error => {
                    this.loading = false;
                    this.validadorResponse(error);
                    reject([]);
                }
            );
        });
    }

    validadorResponse(error: any) {
        if (error.status) {
            if (error.status === 403) {
                this.router.navigate(['/login']);
            } else {
                this.messageSweet.openSweetAlertError(error.message);
            }
        } else {
            this.messageSweet.openSweetAlertError(error.message);
        }
    }
}