import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AuthSerices {

  constructor(private http: HttpClient) {
  }

  public autenticar(email: string, password: string): Observable<any> {
    return this.http.post(`${environment.urlservices}/users/login`, { email, password });
  }

  getUser(): Observable<any> {
    return this.http.get(environment.urlservices + '/users',)
  }
  createUser(email: string, fullname: string, perfil: string): Observable<any> {
    const body = {
      email: email,
      fullname: fullname,
      perfil: perfil
    };
    return this.http.post(environment.urlservices + '/users', body)
  }

  deleteUser(id: string): Observable<any> {
    return this.http.delete(`${environment.urlservices}/users/${id}`,)
  }

  updateUser(id: string, email: string, fullname: string, perfil: string): Observable<any> {
    const body = {
      id,
      email,
      fullname,
      perfil
    };
    return this.http.put(`${environment.urlservices}/users/${id}`, body,)
  }

  public validateStatusEmailChangePassword(body: any): Observable<any> {
    return this.http.post(`${environment.urlservices}/users/sendEmail`, body);
  }
  public verifyStatusLink(body : any): Observable<any> {
    return this.http.post(`${environment.urlservices}/users/statusLink`,body);
  }
  public resetPassword(body :any): Observable<any> {
    return this.http.post(`${environment.urlservices}/users/reset-password`,body);
  }
  public verifyEmail(body : any): Observable<any> {
    return this.http.post(`${environment.urlservices}/users/verify-email`,body);
  }
}