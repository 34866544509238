import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/app/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ObjectiveService {

  constructor(private http: HttpClient) {
  }

  getObjective(token: string): Observable<any> {
    
    return this.http.get(environment.urlservices + '/objectives',)
  }

  createObjective(name_objective: string, token: string): Observable<any> {
    const body = {
      name_objective
    };
    
    return this.http.post(environment.urlservices + '/objectives', body,)
  }

  deleteObjective(id: string, token: string): Observable<any> {
    
    return this.http.delete(`${environment.urlservices}/objectives/${id}`,)
  }

  updateObjective(id: string, name: string, token: string): Observable<any> {
    const body = {
      objective_id: id,
      name_objective: name
    };
    
    return this.http.put(`${environment.urlservices}/objectives/${id}`, body,)
  }
}