import { Injectable } from '@angular/core';
import { ObjectiveService } from './objective.service';
import { Router } from '@angular/router';
import { GeneralServiceMessages } from '../messages-services/utilities-message';

@Injectable({
  providedIn: 'root'
})
export class ObjectiveManager {
  public loading = false;
  public token = '';

  constructor(
    private objective: ObjectiveService,
    private router: Router,
    private messageSweet: GeneralServiceMessages
  ) {
    this.token = sessionStorage.getItem('data') || '';
  }

  getObjective(): Promise<any[]> {
    return new Promise<any[]>((resolve, reject) => {
      this.loading = true;
      this.objective.getObjective(this.token).subscribe(
        response => {
          this.loading = false;
          resolve(response.data);
        },
        error => {
          this.loading = false;
          reject([]);
        }
      );
    });
  }
}