import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Injectable({
    providedIn: 'root'
})
export class GeneralServiceMessages {
    constructor(private router: Router) {

    }
    public openSweetAlertError(texto: string) {
        Swal.fire({ text: texto, icon: 'error', });
    }
    public opensweetalertdngWithThen(texto: string, then: any) {
        Swal.fire({ text: texto, icon: 'error', })
            .then(() => {
                then();
            });
    }

    public opensweetalertwrg(texto: string) {
        Swal.fire({ text: texto, icon: 'warning', });
    }

    public openSweetAlertWarningWithThen(texto: string, then: any) {
        Swal.fire({ text: texto, icon: 'warning', })
            .then(() => {
                then();
            });
    }
    public opensweetalert(texto: string) {
        Swal.fire({
            text: texto,
            icon: 'success'
        });
    }
    public opensweetalertWithThen(texto: string, then: any) {
        Swal.fire({
            text: texto,
            icon: 'success'
        }).then(() => {
            then();
        });
    }
    public openSweetAlertDelete(then: any, thenConfirm: any) {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085D6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            then()
            if (result.isConfirmed) {
                thenConfirm();
            }
        });
    }

    public validadorResponse(error: any) {
        if (error.status) {
            if (error.status === 403) {
                this.router.navigate(['/login']);
            } else if (error.status === 404) {
                this.opensweetalertwrg(error.error);
            } else {
                this.openSweetAlertError(error.message);
            }
        } else {
            this.openSweetAlertError(error.message);
        }
    }
}