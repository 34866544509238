import { Component, OnInit } from '@angular/core';
import { NumberSymbol } from '@angular/common';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { CampaignManagerService } from 'src/app/services/campaign-services/campaign-manager.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModalRef, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { UtmSourceManagerService } from 'src/app/services/utmSources-services/utmSource-manager.service';
import { CampaignService } from 'src/app/services/campaign-services/campaign.service';
import { GeneralServiceMessages } from 'src/app/services/messages-services/utilities-message';
import { ExportToCSV } from '@molteni/export-csv';
import * as XLSX from 'xlsx';


@Component({
  selector: 'app-campaign-administrator',
  templateUrl: './campaign-administrator.component.html',
  styleUrls: ['./campaign-administrator.component.css']
})
export class CampaignAdministratorComponent implements OnInit {
  public utm_campaigns: any[] = [];
  public utm_campaigns_filter: any[] = [];
  public utm_sources: any[] = [];

  public quarter_filter: any[] = [];

  public campaignUpdateForm!: FormGroup;

  public submitted = false;
  public submittedEdit = false;
  public modelFin: NgbDateStruct | undefined;
  public modelInicio: NgbDateStruct | undefined;
  public total = 0;
  public page = 1;
  public rangeRow = 10;
  public step = 0;
  public loading = false;
  public searchControl: any;
  public searchItem = '';
  public id_ramdom = '';
  public closeModal: string = '';
  public modalRef!: NgbModalRef;
  public quarter_select: string = '';

  statusCheck!: boolean;

  constructor(
    private campaignManager: CampaignManagerService,
    private router: Router,
    private formBuilder: FormBuilder,
    private campaignApiServices: CampaignService,
    private messageSwal: GeneralServiceMessages,
    private sourceManager: UtmSourceManagerService,

  ) {
    this.initializedData();

  }

  ngOnInit(): void {
    this.searchControl = this.formBuilder.group({
      searchItemCtrl: ['', Validators.required],
    });

    this.searchControl.get('searchItemCtrl').valueChanges.subscribe((value: string) => {
      this.filterItems(value);
    });

  }

  get f() {
    return this.searchControl.controls;
  }

  async filterItems(searchTerm: string) {
    this.quarter_select = searchTerm != '' ? searchTerm : 'ALL_QUATERS'
    if (searchTerm === "") {
      this.initializedData();
    } else {
      this.utm_campaigns = this.utm_campaigns_filter
        .filter(item => item.name_quarter.toLowerCase() === (searchTerm.toLowerCase()));
    }
  }

  public range(range: NumberSymbol) {
    this.page = 1;
    this.rangeRow = 10;
    this.step = 0;
    this.rangeRow = range;
  }

  public pageChanged(pageIndex: number) {
    this.page = pageIndex;
  }

  async initializedData() {
    this.page = 1;
    this.rangeRow = 10;
    this.step = 0;
    this.utm_campaigns = [];
    this.total = 0;
    this.loading = true;
    this.campaignManager.getCampaigns().then((campaign: any[]) => {
      this.utm_campaigns = campaign;
      this.total = this.utm_campaigns.length;
      this.utm_campaigns_filter = campaign;
      const quarter_unique = [...new Set(campaign.map(itm => itm.name_quarter))]
      this.quarter_filter = quarter_unique.map(name_quarter => ({ name_quarter }));
    });
    this.sourceManager.getUtmSource().then((sources: any[]) => {
      this.utm_sources = sources;
    })
  }


  public editCampaign(campaign: any) {
    this.router.navigateByUrl('/dashboard/campaign_edit', { state: { campaign } });
  }

  deleteCampaign(id: string) {
    this.messageSwal.openSweetAlertDelete(() => { this.loading = true; }, () => {
      this.campaignApiServices.deleteCampaign(id).subscribe(item => {
        this.loading = false;
        this.messageSwal.opensweetalertWithThen(item.message.charAt(0).toUpperCase() + item.message.slice(1), () => { this.initializedData(); });
      }, error => {
        this.loading = false;
        this.messageSwal.openSweetAlertError(error.message);
      });
    });
  }
  checkboxChange() {
    if (this.statusCheck) {
      this.statusCheck = false;
    } else {
      this.statusCheck = true;
    }
  }
  validate() {
    this.submittedEdit = true;
    console.log(this.campaignUpdateForm);
    if (this.campaignUpdateForm.invalid) {
      Swal.fire({ text: 'Fill in the mandatory fields.', timer: 1300, showCancelButton: false });
      return;
    }
  }

  validadorResponse(error: any) {
    if (error.status) {
      if (error.status === 403) {
        this.router.navigate(['/login']);
      } else {
        Swal.fire('Oops...', error.message, 'error')
      }
    } else {
      Swal.fire('Oops...', error.message, 'error')
    }
  }


  public exportToCsv() {
    // const options = {
    //   separator: ',',
    //   sheetName:'CampaignList'
    // };
    // const exporter = new ExportToCSV();

    let listExport = []
    for (const item of this.utm_campaigns) {
      let infoCsv: any = {};
      infoCsv.campaign_name = item.campaing_name;
      const date = item.campaign_created;
      const dateObject = new Date(date);
      const year = dateObject.getFullYear();
      const month = String(dateObject.getMonth() + 1).padStart(2, '0');
      const day = String(dateObject.getDate()).padStart(2, '0');
      const formattedDate = `${year}-${month}-${day}`;
      infoCsv.campaign_created = formattedDate;
      infoCsv.quarter = item.name_quarter;
      infoCsv.fiscal_year = item.name_fiscal_year;
      infoCsv.market = item.name_country;
      infoCsv.channel = item.name_site;
      infoCsv.creative_type = item.name_creative_type;
      infoCsv.linking_url = item.linking_url;
      infoCsv.utm_source = item.utm_source_name;
      infoCsv.utm_medium = item.utm_medium_name;
      infoCsv.utm_campaign = item.utm_campaign;
      infoCsv.utm_id = item.utm_id;
      const source_platform = this.utm_sources.find(it => it.id === item.utm_source_platform)
      infoCsv.utm_source_platform = source_platform.utm_source_name
      infoCsv.utm_content = item.utm_content_name;
      infoCsv.status = item.status === 1 ? 'active' : 'inactive';
      infoCsv.utm_link = item.utm_generated;
      infoCsv.planned_spend = item.buget

      listExport.push(infoCsv); infoCsv
    }

    const name_quarter = this.quarter_select != '' ? this.quarter_select : 'ALL_QUATERS'
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(listExport);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Campaign List');
    const excelFileName = `RCI_${name_quarter}.xlsx`;
    XLSX.writeFile(workbook, excelFileName);








  }
}

