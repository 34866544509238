import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../environments/environment';
import { StorageService } from './storage.service';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class AuthApiService {

    private loggedIn = new BehaviorSubject<boolean>(false);
    private rolLogged = new BehaviorSubject<boolean>(false);

    constructor(private http: HttpClient, private storageService: StorageService, private router: Router) { }

    public logout() {
        this.storageService.remove('profiles');
        this.router.navigate(['/login']);
    }

    get isLoggedIn() {
        if (this.storageService.get('profiles') !== '') {
            this.loggedIn.next(true);
            return this.loggedIn.asObservable();
        } else {
            return this.loggedIn.asObservable();
        }
    }

    public loggedRole(roles: string[]): Observable<boolean> {
        if (this.storageService.get('profiles') !== '') {
            const dataprofile = JSON.parse(this.storageService.get('profiles'));
            const roleLogged = dataprofile.rol;
            let isRole = false;

            roles.forEach(function (rol) {
                if (rol === roleLogged) {
                    isRole = true;
                }
            });
            this.rolLogged.next(isRole);
            return this.rolLogged.asObservable();
        } else {
            return this.rolLogged.asObservable();
        }
    }
}
