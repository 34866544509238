
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UtmContentManagerService } from 'src/app/services/utmContent-services/utmContent-manager.service';
import Swal from 'sweetalert2';
import { NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NumberSymbol } from '@angular/common';
import jwt_decode from 'jwt-decode';
import { Camelize } from 'src/app/services/helpers/stringCamelCaseValidator.validator';
import { UtmContentApiService } from 'src/app/services/utmContent-services/utmContent-api';
import { GeneralServiceMessages } from 'src/app/services/messages-services/utilities-message';
@Component({
  selector: 'app-utm-content',
  templateUrl: './utm-content.component.html',
  styleUrls: ['./utm-content.component.css']
})
export class UtmContentComponent implements OnInit {

  public utmContentCreateForm!: FormGroup;
  public utmContentUpateForm!: FormGroup;
  public utmContents: any[] = [];
  private token = '';
  public total = 0;
  public page = 1;
  public rangeRow = 10;
  public step = 0;
  public loading = false;
  public submitted = false;
  public submittedEdit = false;
  public closeModal: string = '';
  public modalRef!: NgbModalRef;

  constructor(
    private formBuilder: FormBuilder,
    private utmContentManager: UtmContentManagerService,
    private router: Router,
    private modalServices: NgbModal,
    private utmContentService: UtmContentApiService,
    private messageSwal: GeneralServiceMessages,
  ) {
    // const response = sessionStorage.getItem('data');
    // if (response) {
    //   this.token = sessionStorage.getItem('data') || '';
    //   const decoded: any = jwt_decode(response);
    //   if (decoded.perfil !== 'administrator') {
    //     this.router.navigate(['/login']);
    //   }
      this.initializedData()
    // } else {
    //   this.router.navigate(['/login']);
    // }
  }

  ngOnInit(): void {
    this.utmContentCreateForm = this.formBuilder.group({
      nameUtmContentCtrl: ['', Validators.required],
    });

    this.utmContentUpateForm = this.formBuilder.group({
      nameUtmContentUpdateCtrl: ['', Validators.required],

    });
  }
  get formUpdate() {
    return this.utmContentUpateForm.controls;
  }
  get formCreate() {
    return this.utmContentCreateForm.controls;
  }
  initializedData() {
    this.page = 1;
    this.rangeRow = 10;
    this.step = 0;
    this.utmContents = [];
    this.total = 0;
    this.loading = true;
    this.utmContentManager.getUtmContent().then((utmContent: any[]) => {
      this.utmContents = utmContent;
      this.total = this.utmContents.length;

    });
  }
  openModalCreate(modalDataCreate: any) {
    this.modalRef = this.modalServices.open(modalDataCreate, { ariaLabelledBy: 'modal-dialog modal-dialog-centered' });
  }
  createUtmContent() {
    this.submitted = true;
    if (this.utmContentCreateForm.invalid) {
      return;
    } else {
      this.loading = true;
      let name_fiscal_year = this.utmContentCreateForm.controls.nameUtmContentCtrl.value || '';
      name_fiscal_year = Camelize(name_fiscal_year);
      this.utmContentService.createUtmContent(name_fiscal_year, this.token).subscribe(response => {
        this.initializedData();
        this.modalRef.close();
        this.messageSwal.opensweetalert('UtmContent successful update');
      }, error => {
        this.loading = false;
        this.messageSwal.validadorResponse(error);
      });
    }
  }

  updateUtmContent(item: any, modalData: any) {
    const idUtmContent = item.id
    this.submittedEdit = true;
    this.utmContentUpateForm.controls['nameUtmContentUpdateCtrl'].setValue(item.utm_content_name);
    this.modalServices.open(modalData, { ariaLabelledBy: 'modal-basic-title' }).result.then((res) => {
      if (res === 'false') {
        return;
      }
      if (this.utmContentUpateForm.invalid) {
        Swal.fire({ text: 'Todos los campos yo obligatorios.', timer: 500, showCancelButton: false });
        return;
      } else {
        this.loading = true;
        let nameUtmContent = this.utmContentUpateForm.controls.nameUtmContentUpdateCtrl.value || '';
        nameUtmContent = Camelize(nameUtmContent);
        this.utmContentService.updateUtmContent(idUtmContent, nameUtmContent, this.token).subscribe(res => {
          this.initializedData();
          this.messageSwal.opensweetalert('UtmContent successful update');
        }, error => {
          this.loading = false;
          this.messageSwal.validadorResponse(error);
        })
      }
    }, (res) => {
      this.closeModal = `Dismissed ${this.getDismissReason(res)}`;
    });
  }

  deleteUtmContent(id: string) {
    this.messageSwal.openSweetAlertDelete(() => { this.loading = true; }, () => {
      this.utmContentService.deleteUtmContent(id, '').subscribe(item => {
        this.loading = false;
        this.messageSwal.opensweetalertWithThen(item.message.charAt(0).toUpperCase() + item.message.slice(1),
          () => { this.initializedData(); });
      }, error => {
        this.loading = false;
        this.messageSwal.openSweetAlertError(error.message);
      });
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  public openEvent(stepNumb: number) {
    this.step = stepNumb;
  }

  public range(range: NumberSymbol) {
    this.page = 1;
    this.rangeRow = 10;
    this.step = 0;
    this.rangeRow = range;
  }

  public pageChanged(pageIndex: number) {
    this.page = pageIndex;
  }
  validate() {
    this.submittedEdit = true;
    if (this.utmContentUpateForm.invalid) {
      Swal.fire({ text: 'Fill in the mandatory fields.', timer: 1300, showCancelButton: false });
      return;
    }
  }

}

