import { Component, OnInit } from '@angular/core';
import { NumberSymbol } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AgencyApiService } from 'src/app/services/agency-services/agency-api';
import { NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AgencyManagerService } from 'src/app/services/agency-services/agency-manager.service';
import jwt_decode from 'jwt-decode';
import { Camelize } from 'src/app/services/helpers/stringCamelCaseValidator.validator';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-agency',
  templateUrl: './agency.component.html',
  styleUrls: ['./agency.component.css']
})
export class AgencyComponent implements OnInit {
  public agencyForm!: FormGroup;
  public agencyUpdateForm!: FormGroup;
  public submitted = false;
  public submittedEdit = false;
  public agencies: any[] = [];
  private token = '';
  public total = 0;
  public page = 1;
  public rangeRow = 10;
  public step = 0;
  public loading = false;
  public closeModal: string = '';
  modalRef!: NgbModalRef;

  constructor(
    private formBuilder: FormBuilder,
    private agencyManager: AgencyManagerService,
    private agencyService: AgencyApiService,
    private router: Router,
    private modalServices: NgbModal,

  ) {
    const response = sessionStorage.getItem('data');
    if (response) {
      this.token = sessionStorage.getItem('data') || '';
      const decoded: any = jwt_decode(response);
      if (decoded.perfil !== 'administrator') {
        this.router.navigate(['/login']);
      }
      this.initializedData()
    } else {
      this.router.navigate(['/login']);
    }
  }

  ngOnInit(): void {
    this.agencyForm = this.formBuilder.group({
      agencyCtrl: ['', Validators.required]
    });

    this.agencyUpdateForm = this.formBuilder.group({
      agencyUpdateCtrl: ['', Validators.required]
    });
  }
  initializedData() {
    this.page = 1;
    this.rangeRow = 10;
    this.step = 0;
    this.agencies = [];
    this.total = 0;
    this.loading = true;
    this.agencyManager.getAgency().then((countries: any[]) => {
      this.agencies = countries;
      this.total = this.agencies.length;

    });
  }
  get formCreate() {
    return this.agencyForm.controls;
  }

  get formEdit() {
    return this.agencyUpdateForm.controls;
  }

  opeModalCreate(modalDataCreate: any) {
    this.modalRef = this.modalServices.open(modalDataCreate, { ariaLabelledBy: 'modal-basic-title' });
  }

  createAgency() {
    this.submitted = true;
    if (this.agencyForm.invalid) {
      return;
    } else {
      this.loading = true;
      let agencyCtrl = this.agencyForm.controls.agencyCtrl.value || '';
      agencyCtrl = Camelize(agencyCtrl);

      this.agencyService.createAgency(agencyCtrl, this.token).subscribe(response => {
        if (response.sucess) {
          Swal.fire({ text: response.data, timer: 500, showCancelButton: false });
          this.loading = false;
          this.modalRef.close();
          this.initializedData();
        } else {
          Swal.fire('Oops...', (response.data.details)
            ? response.data.details : response.data, 'warning').then(() => {
              this.modalRef.close();
            });
          this.loading = false;
        }
      }, error => {
        this.loading = false;
        this.validadorResponse(error);
      });


    }

  }

  editAgency(item: any, modalData: any) {
    const idagency = item.id
    this.submittedEdit = true;
    this.agencyUpdateForm.controls['agencyUpdateCtrl'].setValue(item.name_agency);
    this.modalServices.open(modalData, { ariaLabelledBy: 'modal-basic-title' }).result.then((res) => {
      if (res === 'false') {
        return;
      }
      if (this.agencyUpdateForm.invalid) {
        Swal.fire({ text: 'Todos los campos yo obligatorios.', timer: 500, showCancelButton: false });
        return;
      } else {
        this.loading = true;
        let nameagencyNew = this.agencyUpdateForm.controls.agencyUpdateCtrl.value || '';
        nameagencyNew = Camelize(nameagencyNew);
        this.agencyService.editAgency(nameagencyNew, idagency, this.token).subscribe(response => {
          if (response.sucess) {
            Swal.fire({ text: response.data, timer: 500, showCancelButton: false });
            this.loading = false;
            this.initializedData();
          } else {
            Swal.fire('Oops...', (response.data.details) ? response.data.details : response.data, 'warning');
            this.loading = false;
          }
        }, error => {
          this.loading = false;
          this.validadorResponse(error);
        })
      }
    }, (res) => {
      this.closeModal = `Dismissed ${this.getDismissReason(res)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  deleteAgency(item: any) {
    this.loading = true;
    this.agencyService.deleteAgency(item.name_agency, this.token).subscribe(response => {
      if (response.sucess) {
        Swal.fire({ text: response.data, timer: 500, showCancelButton: false });
        this.loading = false;
        this.initializedData();
      } else {
        Swal.fire('Oops...', (response.data.details) ? response.data.details : response.data, 'warning');
        this.loading = false;
      }
    }, error => {
      this.loading = false;
      this.validadorResponse(error);
    });
  }

  validadorResponse(error: any) {
    if (error.status) {
      if (error.status === 403) {
        this.router.navigate(['/login']);
      } else {
        Swal.fire('Oops...', error.message, 'error')
      }
    } else {
      Swal.fire('Oops...', error.message, 'error')
    }
  }

  public openEvent(stepNumb: number) {
    this.step = stepNumb;
  }

  public range(range: NumberSymbol) {
    this.page = 1;
    this.rangeRow = 10;
    this.step = 0;
    this.rangeRow = range;
  }

  public pageChanged(pageIndex: number) {
    this.page = pageIndex;
  }
  validate() {
    this.submittedEdit = true;
    if (this.agencyUpdateForm.invalid) {
      Swal.fire({ text: 'Fill in the mandatory fields.', timer: 1300, showCancelButton: false });
      return;
    }
  }

}
