<div class="container">
    <div class="row">
        <div class="col-12">
            <br>
            <div class="container">
                <div class="row justify-content-between ">
                    <div class="col-xl-6 col-l-6 col-md-6 col-sm-12">
                        <h2 class="h5">Campaigns</h2>
                    </div>
                    <div class="col-xl-6 col-l-6 col-md-6 col-sm-12 text-xl-end text-md-end text-sm-start ">
                        <div class="btn-group btn-sm" role="group" aria-label="Button group with nested dropdown">
                            <div class="btn-group" role="group">
                                <form [formGroup]="searchControl">
                                    <div class="dropdown">
                                        <button class="btn btn-info  dropdown-toggle" type="button"
                                            id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true"
                                            aria-expanded="false">
                                            {{(quarter_select === '' || quarter_select === 'ALL_QUATERS')?
                                            "Search by quarter":quarter_select}}</button>
                                        <div class="dropdown-menu form-select" aria-labelledby="dropdownMenuButton">
                                            <a class="dropdown-item" (click)="filterItems('')">All</a>
                                            <a class="dropdown-item" *ngFor="let item of quarter_filter"
                                                (click)="filterItems(item.name_quarter)">{{item.name_quarter}}</a>
                                        </div>
                                    </div>
                                </form>

                            </div>
                            <button type="submit" class="btn btn-primary btn-sm btnNet" (click)="exportToCsv()">
                                <span><i class="bi bi-filetype-xlsx"></i> Export</span>
                            </button>
                        </div>

                    </div>
                </div>
            </div>
            <label for="inputExchange">&nbsp;</label><br>
        </div>
        <div class="row">
            <div class="col-xl-12 col-l-12 col-md-12  col-sm-12">
                <div class="item-wrapper">
                    <div class="table col-md-12">
                        <table class="table-responsive-xl table-striped table-hover small-font">
                            <thead>
                                <tr>
                                    <th>Market</th>
                                    <th>Name campaign</th>
                                    <th>Quarter</th>
                                    <th>Channel</th>
                                    <th>Objective</th>
                                    <th>Creative Type</th>
                                    <th>Campaign Created </th>
                                    <th>Source</th>
                                    <th>Medium</th>
                                    <th>Content</th>
                                    <th>Status</th>
                                    <th colspan="2">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                <tr
                                    *ngFor="let item of utm_campaigns | paginate: { itemsPerPage: rangeRow, currentPage: page , totalItems: total}; let i = index">
                                    <td style="white-space: nowrap;">{{item.name_country}}</td>
                                    <td style="white-space: nowrap;">{{item.campaing_name}}</td>
                                    <td style="white-space: nowrap;">{{item.name_quarter}}</td>
                                    <td style="white-space: nowrap;">{{item.name_site}}</td>
                                    <td style="white-space: nowrap;">{{item.name_objective}}</td>
                                    <td style="white-space: nowrap;">{{item.name_creative_type}}</td>
                                    <td style="white-space: nowrap;">{{item.campaign_created | date:'yyyy-MM-dd'}}
                                    </td>
                                    <td style="white-space: nowrap;">{{item.utm_source_name}}</td>
                                    <td style="white-space: nowrap;">{{item.utm_medium_name}}</td>
                                    <td style="white-space: nowrap;">{{item.utm_content_name}}</td>
                                    <td style="white-space: nowrap;">{{item.status==true? 'Active' : 'Inactive'}}
                                    </td>
                                    <td style="white-space: nowrap;">
                                        <a href="javascript: void(0);" (click)="editCampaign(item)"><i
                                                class="bi bi-pencil-square text-dark"></i></a>
                                    </td>
                                    <td style="white-space: nowrap;">
                                        <a href="javascript: void(0);" (click)="deleteCampaign(item.id)"><i
                                                class="bi bi-trash-fill text-dark"></i></a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="dataEmpty" *ngIf="total < 1">
                            <p>No records found</p>
                        </div>
                        <div *ngIf="total > 0" class="table-control">
                            <div class="container">
                                <div class="row">
                                    <div class="col-12 text-center">
                                        <pagination-controls (pageChange)="pageChanged(page=$event)" previousLabel=""
                                            nextLabel="">
                                        </pagination-controls>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-xl-10 col-l-10 col-md-10 col-sm-10">
        </div>
        <div class="col-xl-2 col-l-2 col-md-2 col-sm-2">
            <select class="form-select form-select-sm" [ngModel]="rangeRow" (ngModelChange)="range($event)">
                <option value="" disabled>Number of rows</option>
                <option value="10" selected>10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
            </select>
        </div>
    </div>
</div>