import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SiteApiService {

  constructor(private http: HttpClient) { }

  getSite(token: string): Observable<any> {
    return this.http.get(environment.urlservices + '/sites',)
  }
  createSite(name_site: string, source: number[],medium: number[]): Observable<any> {
    const body = {
      name_site,
      source,
      medium
    };
    return this.http.post(environment.urlservices + '/sites', body,)
  }

  deleteSite(id: string, token: string): Observable<any> {

    return this.http.delete(`${environment.urlservices}/sites/${id}`,)
  }

  updateSite(id: string, name: string, ids_sources: number[],ids_mediums: number[]): Observable<any> {
    const body = {
      site_id: id,
      name_site: name,
      source: ids_sources,
      medium: ids_mediums

    };

    return this.http.put(`${environment.urlservices}/sites/${id}`, body,)
  }
}