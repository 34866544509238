<ngx-loading [show]="loading" [config]="{fullScreenBackdrop: true}"></ngx-loading>
<div class="container">
    <div class="row">
        <div class="col-12">
            <br>
            <div class="container">
                <div class="row">
                    <div class="col-6">
                        <h2 class="h5">Quarters</h2>
                    </div>
                    <div class="col-6 text-end">
                        <button type="submit" class="btn btn-primary  btnNet" (click)="openModalCreate(modalDataCreate)">
                            <span><i class="bi bi-plus-circle-fill bi-size-3x"> Add quarter</i></span>
                        </button>
                    </div>
                </div>
            </div>
            <label for="inputExchange">&nbsp;</label><br>
        </div>
        <div class="col-12">
            <div class="">
                <div class="item-wrapper">
                    <div class="table">
                        <table class="table table-striped table-hover">
                            <thead>
                                <tr>
                                    <th>Id quarter</th>
                                    <th>Name quarter</th>
                                    <th colspan="2">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    *ngFor="let item of quarters | paginate: { itemsPerPage: rangeRow, currentPage: page , totalItems: total}; let i = index">
                                    <td>{{item.id}}</td>
                                    <td>{{item.name_quarter}}</td>
                                    <td>
                                        <a href="javascript: void(0);" (click)="updateQuarter(item, modalData)"><i class="bi bi-pencil-square text-dark"></i></a>
                                    </td>
                                    <td>
                                        <a href="javascript: void(0);" (click)="deleteQuarter(item.id)"><i class="bi bi-trash-fill text-dark"></i></a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="dataEmpty" *ngIf="quarters.length < 1">
                            <p>No records found</p>
                        </div>
                        <div *ngIf="quarters.length > 0" class="table-control">
                            <div class="container">
                                <div class="row">
                                    <div class="col-12 text-center">
                                        <pagination-controls (pageChange)="pageChanged(page=$event)" previousLabel=""
                                            nextLabel="">
                                        </pagination-controls>
                                    </div>
                                    <div class="col-12 text-end">
                                        <select class="mdb-select colorful-select dropdown-primary mt-2 hidden-md-down"
                                            [ngModel]="rangeRow" (ngModelChange)="range($event)">
                                            <option value="" disabled>Number of rows</option>
                                            <option value="10" selected>10</option>
                                            <option value="25">25</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #modalData let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Edit quarter</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="quarterUpateForm">
            <div class="form-row">
                <div class="col-xs-12 col-sm-12 col-md-12">
                    <label for="inputExchange">Name quarter</label>
                    <input type="text" class="form-control" placeholder="Name quarter"
                        formControlName="nameQuarterUpdateCtrl"
                        [ngClass]="{ 'is-invalid':formUpdate.nameQuarterUpdateCtrl.errors && submittedEdit}">
                    <div *ngIf="formUpdate.nameQuarterUpdateCtrl.errors?.required" class="invalid-feedback">
                        Name quarter is required
                    </div>
                </div>

            </div>
        </form>
    </div>
    <div class="modal-footer text-center">
        <button type="button" class="btn btn-primary "
            (click)="!quarterUpateForm.invalid ? modal.close() : validate()">Update quarter</button>
        <button type="button" class="btn btn-secondary" (click)="modal.close('false')">Cancel</button>
    </div>
</ng-template>

<ng-template #modalDataCreate let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Create quarter</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="quarterCreateForm">
            <div class="form-row">
                <div class="col-xs-12 col-sm-12 col-md-12">
                    <label for="inputExchange">Name quarter</label>
                    <input type="text" class="form-control" placeholder="Name quarter"
                        formControlName="nameQuarterCtrl"
                        [ngClass]="{ 'is-invalid':formCreate.nameQuarterCtrl.errors && submitted}">
                    <div *ngIf="formCreate.nameQuarterCtrl.errors" class="invalid-feedback">
                        Name country is required.
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer text-center">
        <button type="submit" class="btn btn-primary "(click)="createQuarter()">Create quarter</button>
        <button type="button" class="btn btn-secondary" (click)="modal.close('false')">Cancel</button>
    </div>
</ng-template>