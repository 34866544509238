import { Injectable } from '@angular/core';
import { UtmContentApiService } from './utmContent-api';
import { Router } from '@angular/router';
import { GeneralServiceMessages } from '../messages-services/utilities-message';

@Injectable({
    providedIn: 'root'
})
export class UtmContentManagerService {
    public loading = false;
    public token = '';

    constructor(
        private utmContentService: UtmContentApiService,
        private router: Router,
        private messageSweet: GeneralServiceMessages
    ) {
        this.token = sessionStorage.getItem('data') || '';
    }
    getUtmContent() {
        return new Promise<any[]>((resolve, reject) => {
            this.loading = true;
            this.utmContentService.getUtmContent(this.token).subscribe(
                response => {
                    this.loading = false;
                    resolve(response.data);
                },
                error => {
                    this.loading = false;
                    reject([]);
                }
            );
        });

    }
}