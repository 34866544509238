import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './routing/app-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { CampaignComponent } from './components/dashboard/campaign/campaign.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ClipboardModule } from 'ngx-clipboard';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxCurrencyModule } from 'ngx-currency';
import { CountriesComponent } from './components/dashboard/countries/countries.component';
import { AgencyComponent } from './components/dashboard/agency/agency.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from '@angular/material/button';
import { MatStepperModule } from '@angular/material/stepper';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { CampaignAdministratorComponent } from './components/dashboard/campaign-administrator/campaign-administrator.component';
import { CampaignUpdateComponent } from './components/dashboard/campaign-administrator/campaign-update/campaign-update.component';
import { ObjectivesComponent } from './components/dashboard/objectives/objectives.component';
import { SiteComponent } from './components/dashboard/site/site.component';
import { QuarterComponent } from './components/dashboard/quarter/quarter.component';
import { FiscalYearComponent } from './components/dashboard/fiscal-year/fiscal-year.component';
import { CreativeTypeComponent } from './components/dashboard/creative-type/creative-type.component';
import { UtmSourceComponent } from './components/dashboard/utm-source/utm-source.component';
import { UtmMediumComponent } from './components/dashboard/utm-medium/utm-medium.component';
import { UtmContentComponent } from './components/dashboard/utm-content/utm-content.component';
import { AuthInterceptor } from './services/interceptor.service';
import { AuthGuard } from './services/auth.guard';
import { UsersComponent } from './components/dashboard/users/users.component';
import { UserAddComponent } from './components/dashboard/users/user-add/user-add.component';
import { UserEditComponent } from './components/dashboard/users/user-edit/user-edit.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { NewPasswordComponent } from './components/new-password/new-password.component';
import { MultiSelectAllModule, MultiSelectModule } from '@syncfusion/ej2-angular-dropdowns';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    CampaignComponent,
    CountriesComponent,
    AgencyComponent,
    CampaignAdministratorComponent,
    CampaignUpdateComponent,
    ObjectivesComponent,
    SiteComponent,
    QuarterComponent,
    FiscalYearComponent,
    CreativeTypeComponent,
    UtmSourceComponent,
    UtmMediumComponent,
    UtmContentComponent,
    UsersComponent,
    UserAddComponent,
    UserEditComponent,
    ResetPasswordComponent,
    NewPasswordComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserModule,
    NgbModule,
    HttpClientModule,
    ClipboardModule,
    NgxPaginationModule,
    NgxCurrencyModule,
    BrowserAnimationsModule,
    MatStepperModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    NgMultiSelectDropDownModule.forRoot(),
    MultiSelectAllModule,

  ],
  providers: [
    [AuthGuard,],

    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]

})
export class AppModule { }
