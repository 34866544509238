import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UtmSourceApiService {

  constructor(private http: HttpClient) {
  }
  getUtmSource(): Observable<any> {
    
    return this.http.get(environment.urlservices + '/utm-sources',)
  }

  createUtmSource(utm_source_name: string): Observable<any> {
    const body = {
      utm_source_name
    };
    
    return this.http.post(environment.urlservices + '/utm-sources', body,)
  }

  deleteUtmSource(id: string): Observable<any> {
    
    return this.http.delete(`${environment.urlservices}/utm-sources/${id}`,)
  }

  updateUtmSource(id: string, name: string): Observable<any> {
    const body = {
      utm_source_id: id,
      utm_source_name: name
    };
    
    return this.http.put(`${environment.urlservices}/utm-sources/${id}`, body,)
  }
}