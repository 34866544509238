<div class="container">
  <mat-horizontal-stepper [linear]="isLinear" #stepper>
    <mat-step [stepControl]="campaignForm">
      <ng-template matStepLabel>Step 1</ng-template>
      <form [formGroup]="campaignForm">

        <div class="form-row">
          <div class="col-xs-12 col-sm-6 col-md-6 mt-2 ">
            <div class="form-floating">
              <select class="form-select" formControlName="countryNameCtrl"
                [ngClass]="{ 'is-invalid':f['countryNameCtrl'].errors && submitted}"
                (ngModelChange)="generateUtmCampaign($event)">
                <option value="" selected>Select market</option>
                <option *ngFor="let country of countries" value="{{country.id}}">{{country.name_country}}</option>
              </select>
              <label for="inputTipoCanal">Market</label>
              <div *ngIf="f.countryNameCtrl.errors?.required" class="invalid-feedback">
                Market is required.
              </div>
            </div>
            <p class="text-muted"><small>Where will you place the link?</small></p>
          </div>

          <div class="col-xs-12 col-sm-6 col-md-6 mt-2 ">
            <div class="form-floating">
              <select class="form-select" formControlName="objectiveCtrl"
                [ngClass]="{ 'is-invalid':f.objectiveCtrl.errors && submitted}">
                <option value="" selected>Select objective</option>
                <option *ngFor="let objective of objectives" value="{{objective.id}}">{{objective.name_objective}}
                </option>
              </select>
              <label for="inputTipoCanal">Objective</label>
              <div *ngIf="f.objectiveCtrl.errors?.required" class="invalid-feedback">
                Objective is required.
              </div>
            </div>
            <p class="text-muted"><small>Audience/ Tactic/ Creative concept</small></p>

          </div>

          <div class="col-xs-12 col-sm-6 col-md-6 mt-2 ">
            <div class="form-floating">
              <input type="text" class="form-control" id="floatingInput" placeholder="Campaign name"
                formControlName="nameCampaignCtrl"
                [ngClass]="{ 'is-invalid':f.nameCampaignCtrl.errors && submitted, 'spaces-text': isValidNameCampaign()}">
              <label for="floatingInput">Campaign name</label>
              <div *ngIf="f.nameCampaignCtrl.errors?.required" class="invalid-feedback">
                Campaign name is required.
              </div>
              <div *ngIf="f.nameCampaignCtrl.errors?.spacesText" class="invalid-feedback">
                Campaign has spaces.
              </div>
            </div>

            <p class="text-muted"><small>Campaign Name (separate words with "_" )</small></p>
          </div>
          <div class="col-xs-12 col-sm-6 col-md-6 mt-2 ">
            <div class="form-floating">
              <select class="form-select" formControlName="quarterCtrl"
                [ngClass]="{ 'is-invalid':f.quarterCtrl.errors && submitted}">
                <option value="" selected>Select quarter</option>
                <option *ngFor="let quarter of quarters" value="{{quarter.id}}">{{quarter.name_quarter}}</option>
              </select>
              <label for="inputTipoCanal">Quarter</label>
              <div *ngIf="f.quarterCtrl.errors?.required" class="invalid-feedback">
                Quarter is required.
              </div>
            </div>
            <p class="text-muted"><small>The marketing quarter, a three (3) month period</small></p>
          </div>
          <div class="col-xs-12 col-sm-6 col-md-6 mt-2 ">
            <div class="form-floating">
              <select class="form-select" formControlName="siteCtrl"
                [ngClass]="{ 'is-invalid':f.siteCtrl.errors && submitted}" (change)="loadSourcesAndMediums($event)">
                <option value="" selected>Select channel</option>
                <option *ngFor="let site of sites" value="{{site.id}}">{{site.name_site}}</option>
              </select>
              <label for="inputTipoCanal">Channel</label>
              <div *ngIf="f.quarterCtrl.errors?.required" class="invalid-feedback">
                Channel is required.
              </div>
            </div>
            <p class="text-muted"><small>The specific medium or platform used.</small></p>

          </div>
          <div class="col-xs-12 col-sm-6 col-md-6 mt-2 ">
            <div class="form-floating">
              <select class="form-select" formControlName="fiscalYearCtrl"
                [ngClass]="{ 'is-invalid':f.fiscalYearCtrl.errors && submitted}">
                <option value="" selected>Select fiscal year</option>
                <option *ngFor="let fiscalYear of fiscalYears" value="{{fiscalYear.id}}">
                  {{fiscalYear.name_fiscal_year}}
                </option>
              </select>
              <label for="inputTipoCanal">Fiscal year</label>
              <div *ngIf="f.quarterCtrl.errors?.required" class="invalid-feedback">
                Fiscal year is required.
              </div>
            </div>
            <p class="text-muted"><small>Planned program of activities</small></p>
          </div>

          <div class="col-xs-12 col-sm-6 col-md-6 mt-2">
            <div class="input-group mb-3">
              <span class="input-group-text">$</span>
              <input type="text" class="form-control" id="floatingInput" aria-label="Amount (to the nearest dollar)"
                placeholder="Planned Spend" formControlName="bugetCtrl"
                [ngClass]="{ 'is-invalid':f.bugetCtrl.errors && submitted}">

              <span class="input-group-text">.00</span>

              <div *ngIf="f.bugetCtrl.errors?.required" class="invalid-feedback">
                Planned Spend is required.
              </div>
            </div>
            <p class="text-muted"><small>Planned spend (Suggested expense)</small></p>
          </div>
        </div>
        <div class="mx-auto p-2 " style="width: 90px;">
          <button mat-button matStepperNext class="btn btn-dark ">Next</button>
        </div>
      </form>
    </mat-step>

    <mat-step [stepControl]="campaignForm" label="Step 2">
      <form [formGroup]="campaignForm">
        <div class="form-row">

          <div class="col-xs-12 col-sm-6 col-md-6 mt-2 ">
            <div class="form-floating">
              <select class="form-select" formControlName="creativeTypeCtrl"
                [ngClass]="{ 'is-invalid':f.creativeTypeCtrl.errors && submitted}">
                <option value="" selected>Select Creative type</option>
                <option *ngFor="let creativeType of creativeTypes" value="{{creativeType.id}}">
                  {{creativeType.name_creative_type}}
                </option>
              </select>
              <label for="inputTipoCanal">Creative type</label>
              <div *ngIf="f.quarterCtrl.errors?.required" class="invalid-feedback">
                Creative type is required.
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="col-xs-12 col-sm-12 col-md-12 mt-2 border rounded">
              <label for="customRange1" class="form-label">Rotation</label>
              <span> {{ f.rotationCtrl.value }}%</span>
              <input type="range" class="form-range" formControlName="rotationCtrl" min="0" max="100"
                [value]="f.rotationCtrl.value">
            </div>
            <p class="text-muted"><small>Rotation %</small></p>
          </div>
          <div class="col-xs-12 col-sm-6 col-md-6  mt-2">
            <div class="form-floating">

              <div class="input-group">
                <input class="form-control" placeholder="Creative Start Date" name="dpInicio" [(ngModel)]="modelInicio"
                  ngbDatepicker #d="ngbDatepicker" formControlName="startDateCtrl"
                  (ngModelChange)="changeConversion($event)" [readonly]="true" container="body">
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                </div>
                <div *ngIf="f.startDateCtrl.errors?.required" class="invalid-feedback">
                  Begin Date is required.
                </div>
              </div>
              <p class="text-muted"><small>Creative Start Date</small></p>
            </div>

          </div>
          <div class="col-xs-12 col-sm-6 col-md-6 mt-2">
            <div class="input-group">
              <input class="form-control" placeholder="Creative End Date" name="dpFin" [(ngModel)]="modelFin"
                ngbDatepicker #d1="ngbDatepicker" formControlName="endDateCtrl"
                (ngModelChange)="changeConversion($event)" [readonly]="true" container="body">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d1.toggle()" type="button"></button>
              </div>
            </div>
            <p class="text-muted"><small>Creative End Date</small></p>

          </div>

          <div class="col-xs-12 col-sm-6 col-md-6 mt-2 ">
            <div class="form-floating">
              <input type="text" class="form-control" id="floatingInput" placeholder="Linking URL"
                formControlName="urlPageCtrl" [ngClass]="{ 'is-invalid':f.urlPageCtrl.errors && submitted}">
              <label for="floatingInput">Linking URL</label>
              <div *ngIf="f.urlPageCtrl.errors?.required" class="invalid-feedback">
                Linking URL is required.
              </div>
            </div>
            <p class="text-muted"><small>URL Landing Page</small></p>

          </div>

          <div class="col-xs-12 col-sm-6 col-md-6 mt-2">
            <div class="form-check">
              <input class="form-check-input custom-checkbox" type="checkbox" id="statusCheck"
               [(ngModel)]="statusCheck" formControlName="statusCtrl"
                (change)="checkboxChange()" checked>
              <label class="form-check-label custom-checkbox" for="statusCheck">
                {{ statusCheck ? 'Active' : 'Inactive'}}
              </label>
            </div>
          </div>

        </div>
        <br>
        <div class="col-xs-12 col-sm-12 col-md-12 mt-2">
          <div class="row">
            <div class="col-6 p-2 text-end " style="width: 90px;">
              <button mat-button matStepperPrevious class="btn btn-dark ">Back</button>
            </div>
            <div class="col-6 p-2 text-start " style="width: 90px;">
              <button mat-button matStepperNext class="btn btn-dark ">Next</button>
            </div>
          </div>
        </div>
      </form>
    </mat-step>
    <!-- STEP 3 -->
    <mat-step [stepControl]="campaignForm">
      <form [formGroup]="campaignForm">
        <ng-template matStepLabel>Step 3</ng-template>
        <div class="form-row">

          <div class="col-xs-12 col-sm-6 col-md-6 mt-2 ">
            <div class="form-floating">
              <select class="form-select" formControlName="utmSourceCtrl"
                [ngClass]="{ 'is-invalid':f.utmSourceCtrl.errors && submitted}"
                (ngModelChange)="generateUtmCampaign($event)">
                <option value="" selected>{{f.siteCtrl.value !='' ? "Select UTM Source":"You must select a channel"}}
                </option>
                <option *ngFor="let utmSource of utmSources" value="{{utmSource.id}}">
                  {{utmSource.utm_source_name}}
                </option>
              </select>
              <label for="inputTipoCanal">UTM source</label>
              <div *ngIf="f.utmSourceCtrl.errors?.required" class="invalid-feedback">
                UTM source is required.
              </div>
            </div>
            <p class="text-muted"><small>Where will you place the link?</small></p>
          </div>

          <div class="col-xs-12 col-sm-6 col-md-6 mt-2 ">
            <fieldset disabled>
              <div class="form-floating">
                <input type="text" class="form-control" id="floatingInput" placeholder="UTM campaign"
                  formControlName="utmCampaignCtrl" [ngClass]="{ 'is-invalid':f.utmCampaignCtrl.errors && submitted}">
                <label for="floatingInput">UTM campaign</label>
              </div>

            </fieldset>
            <p class="text-muted"><small>Product, slogan, promo code, for example: spring_sale</small></p>
          </div>

          <div class="col-xs-12 col-sm-6 col-md-6 mt-2 ">
            <div class="form-floating">
              <select class="form-select" formControlName="utmMediumCtrl"
                [ngClass]="{ 'is-invalid':f.utmMediumCtrl.errors && submitted}" (ngModelChange)="generateUtmID($event)">
                <option value="" selected>{{f.siteCtrl.value !='' ? "Select UTM Medium":"You must select a channel"}}
                </option>
                <option *ngFor="let utmMedium of utmMediums" value="{{utmMedium.id}}">
                  {{utmMedium.utm_medium_name}}
                </option>
              </select>
              <label for="inputTipoCanal">UTM medium</label>
              <div *ngIf="f.utmMediumCtrl.errors?.required" class="invalid-feedback">
                UTM medium is required.
              </div>
            </div>
            <p class="text-muted"><small>Medium is the type of channel.</small></p>

          </div>


          <div class="col-xs-12 col-sm-6 col-md-6 mt-2 ">
            <fieldset disabled>
              <div class="form-floating">
                <input type="text" class="form-control" id="floatingInput" placeholder="UTM campaign"
                  formControlName="utmIdCtrl" [ngClass]="{ 'is-invalid':f.utmIdCtrl.errors && submitted}">
                <label for="floatingInput">UTM ID</label>
              </div>
            </fieldset>
            <p class="text-muted"><small>Used to identify a specific campaign or promotion.</small></p>
          </div>

          <div class="col-xs-12 col-sm-6 col-md-6 mt-2 ">
            <div class="form-floating">
              <select class="form-select" formControlName="sourcePlatformCtrl"
                [ngClass]="{ 'is-invalid':f.sourcePlatformCtrl.errors && submitted}">
                <option value="" selected>Select Source Platform</option>
                <option *ngFor="let utmSource of sourcePlatform" value="{{utmSource.id}}">{{utmSource.utm_source_name}}
                </option>
              </select>
              <label for="inputTipoCanal">Source platform</label>
              <div *ngIf="f.sourcePlatformCtrl.errors?.required" class="invalid-feedback">
                Source platform is required.
              </div>
            </div>
            <p class="text-muted"><small> The platform responsible for directing traffic to a given Analytics
                property.</small></p>
          </div>

          <div class="col-xs-12 col-sm-6 col-md-6 mt-2 ">
            <div class="form-floating">
              <select class="form-select" formControlName="utmContentCtrl"
                [ngClass]="{ 'is-invalid':f.utmContentCtrl.errors && submitted}">
                <option value="" selected>Select UTM Content</option>
                <option *ngFor="let utmContent of utmContents" value="{{utmContent.id}}">
                  {{utmContent.utm_content_name}}
                </option>
              </select>
              <label for="inputTipoCanal">UTM content</label>
              <div *ngIf="f.utmContentCtrl.errors?.required" class="invalid-feedback">
                UTM content is required.
              </div>
            </div>
            <p class="text-muted"><small>This UTM is useful for distinguishing creatives between the same campaign.
              </small></p>

          </div>

        </div>
        <br>
        <div class="col-xs-12 col-sm-12 col-md-12 mt-2">
          <div class="row">
            <div class="col-4 p-2 text-end " style="width: 90px;">
              <button mat-button matStepperPrevious class="btn btn-dark ">Back</button>
            </div>
            <div class="col-4 p-2 text-center " style="width: 90px;">
              <button mat-button class="btn btn-dark " (click)="generateUtm()">Generate UTM</button>
            </div>
            <div class="col-4 p-2 text-start " style="width: 90px;">
              <button mat-button class="btn btn-dark"
                (click)="f.utmGeneratedCtrl.value === '' ? generateUtm() : '';createCampaign()">Save</button>
            </div>
          </div>
        </div>
      </form>

    </mat-step>
  </mat-horizontal-stepper>
  <div class="container" [hidden]="!hiddenUtm">
    <form [formGroup]="campaignForm">
      <div class="form-floating">
        <div class="textarea-toolbar">
          <div class="d-flex align-items-center highlight-toolbar ps-3 pe-2 py-1 border-0 border-top border-bottom">
            <label for="floatingTextarea">UTM</label>
            <div class="d-flex ms-auto">
              <button type="button" class="btn btn-light" (click)="copyToClipboard()"><i class="bi bi-clipboard"></i>
                Copy</button>
            </div>
          </div>
        </div>
        <fieldset disabled>
          <textarea class="form-control" id="floatingTextarea" formControlName="utmGeneratedCtrl"
            style="height: 100px"></textarea>

        </fieldset>
      </div>
    </form>
  </div>
</div>