import * as SecureLS from 'secure-ls';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class StorageService {

  private LS = new SecureLS({ encodingType: 'aes', encryptionSecret: 'C4N4LS3CUR0' });

  constructor() {
  }

  set(key: string, value: any) {
    this.LS.set(key, value);
  }

  remove(key: string) {
    this.LS.remove(key);
  }

  get(key: string) {
    return this.LS.get(key);
  }

  clear() {
    this.LS.removeAll();
  }
}
