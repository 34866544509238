import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UtmContentApiService {

  constructor(private http: HttpClient) {
  }
  getUtmContent(token: string): Observable<any> {
    
    return this.http.get(environment.urlservices + '/utm-contents',)
  }
  createUtmContent(utm_content_name: string, token: string): Observable<any> {
    const body = {
      utm_content_name
    };
    return this.http.post(environment.urlservices + '/utm-contents', body,)
  }

  deleteUtmContent(id: string, token: string): Observable<any> {
    
    return this.http.delete(`${environment.urlservices}/utm-contents/${id}`,)
  }

  updateUtmContent(id: string, name: string, token: string): Observable<any> {
    const body = {
      utm_content_id: id,
      utm_content_name: name
    };
    return this.http.put(`${environment.urlservices}/utm-contents/${id}`, body)
  }
}