import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class QuarterApiService {

  constructor(private http: HttpClient) {
  }
  getQuarter(token: string): Observable<any> {
    
    return this.http.get(environment.urlservices + '/quarters',)
  }
  createQuarter(name_quarter: string, token: string): Observable<any> {
    const body = {
      name_quarter
    };
    return this.http.post(environment.urlservices + '/quarters', body,)
  }

  deleteQuarter(id: string, token: string): Observable<any> {
    return this.http.delete(`${environment.urlservices}/quarters/${id}`,)
  }

  updateQuarter(id: string, name: string, token: string): Observable<any> {
    const body = {
      quarter_id: id,
      name_quarter: name
    };
    
    return this.http.put(`${environment.urlservices}/quarters/${id}`, body,)
  }
}