import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UtmMediumApiService {

  constructor(private http: HttpClient) {
  }
  getUtmMedium(): Observable<any> {
    
    return this.http.get(environment.urlservices + '/utm-mediums',)
  }
  createUtmMedium(utm_medium_name: string, token: string): Observable<any> {
    const body = {
      utm_medium_name
    };
    return this.http.post(environment.urlservices + '/utm-mediums', body,)
  }

  deleteUtmMedium(id: string, token: string): Observable<any> {
    
    return this.http.delete(`${environment.urlservices}/utm-mediums/${id}`,)
  }

  updateUtmMedium(id: string, name: string, token: string): Observable<any> {
    const body = {
      utm_medium_id: id,
      utm_medium_name: name
    };
    
    return this.http.put(`${environment.urlservices}/utm-mediums/${id}`, body,)
  }
}