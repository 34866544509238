
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CreativeTypeManagerService } from 'src/app/services/creativeType-services/creativeType-manager.service';
import Swal from 'sweetalert2';
import { NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NumberSymbol } from '@angular/common';
import jwt_decode from 'jwt-decode';
import { Camelize } from 'src/app/services/helpers/stringCamelCaseValidator.validator';
import { CreativeTypeApiService } from 'src/app/services/creativeType-services/creativeType-api';
import { GeneralServiceMessages } from 'src/app/services/messages-services/utilities-message';
@Component({
  selector: 'app-creative-type',
  templateUrl: './creative-type.component.html',
  styleUrls: ['./creative-type.component.css']
})
export class CreativeTypeComponent implements OnInit {

  public creativeTypeCreateForm!: FormGroup;
  public creativeTypeUpateForm!: FormGroup;
  public creativeTypes: any[] = [];
  private token = '';
  public total = 0;
  public page = 1;
  public rangeRow = 10;
  public step = 0;
  public loading = false;
  public submitted = false;
  public submittedEdit = false;
  public closeModal: string = '';
  public modalRef!: NgbModalRef;

  constructor(
    private formBuilder: FormBuilder,
    private creativeTypeManager: CreativeTypeManagerService,
    private router: Router,
    private modalServices: NgbModal,
    private creativeTypeService: CreativeTypeApiService,
    private messageSwal: GeneralServiceMessages,
  ) {
    // const response = sessionStorage.getItem('data');
    // if (response) {
    //   this.token = sessionStorage.getItem('data') || '';
    //   const decoded: any = jwt_decode(response);
    //   if (decoded.perfil !== 'administrator') {
    //     this.router.navigate(['/login']);
    //   }
    this.initializedData()
    // } else {
    //   this.router.navigate(['/login']);
    // }
  }

  ngOnInit(): void {
    this.creativeTypeCreateForm = this.formBuilder.group({
      nameCreativeTypeCtrl: ['', Validators.required],
    });

    this.creativeTypeUpateForm = this.formBuilder.group({
      nameCreativeTypeUpdateCtrl: ['', Validators.required],

    });
  }
  get formUpdate() {
    return this.creativeTypeUpateForm.controls;
  }
  get formCreate() {
    return this.creativeTypeCreateForm.controls;
  }
  initializedData() {
    this.page = 1;
    this.rangeRow = 10;
    this.step = 0;
    this.creativeTypes = [];
    this.total = 0;
    this.loading = true;
    this.creativeTypeManager.getCreativeType().then((creativeType: any[]) => {
      this.creativeTypes = creativeType;
      this.total = this.creativeTypes.length;

    });
  }
  openModalCreate(modalDataCreate: any) {
    this.modalRef = this.modalServices.open(modalDataCreate, { ariaLabelledBy: 'modal-dialog modal-dialog-centered' });
  }
  createCreativeType() {
    this.submitted = true;
    if (this.creativeTypeCreateForm.invalid) {
      return;
    } else {
      this.loading = true;
      let name_creative_type = this.creativeTypeCreateForm.controls.nameCreativeTypeCtrl.value || '';
      name_creative_type = Camelize(name_creative_type);
      this.creativeTypeService.createCreativeType(name_creative_type, this.token).subscribe(response => {
        this.initializedData();
        this.modalRef.close();
        this.messageSwal.opensweetalert('CreativeType successful update');
      }, error => {
        this.loading = false;
        this.messageSwal.validadorResponse(error);
      });
    }
  }

  updateCreativeType(item: any, modalData: any) {
    const idCreativeType = item.id
    this.submittedEdit = true;
    this.creativeTypeUpateForm.controls['nameCreativeTypeUpdateCtrl'].setValue(item.name_creative_type);
    this.modalServices.open(modalData, { ariaLabelledBy: 'modal-basic-title' }).result.then((res) => {
      if (res === 'false') {
        return;
      }
      if (this.creativeTypeUpateForm.invalid) {
        Swal.fire({ text: 'Todos los campos yo obligatorios.', timer: 500, showCancelButton: false });
        return;
      } else {
        this.loading = true;
        let nameCreativeType = this.creativeTypeUpateForm.controls.nameCreativeTypeUpdateCtrl.value || '';
        nameCreativeType = Camelize(nameCreativeType);
        this.creativeTypeService.updateCreativeType(idCreativeType, nameCreativeType, this.token).subscribe(res => {
          this.initializedData();
          this.messageSwal.opensweetalert('CreativeType successful update');
        }, error => {
          this.loading = false;
          this.messageSwal.validadorResponse(error);
        })
      }
    }, (res) => {
      this.closeModal = `Dismissed ${this.getDismissReason(res)}`;
    });
  }

  deleteCreativeType(id: string) {
    this.messageSwal.openSweetAlertDelete(() => { this.loading = true; }, () => {
      this.creativeTypeService.deleteCreativeType(id, '').subscribe(item => {
        this.loading = false;
        this.messageSwal.opensweetalertWithThen(item.message.charAt(0).toUpperCase() + item.message.slice(1),
          () => { this.initializedData(); });
      }, error => {
        this.loading = false;
        this.messageSwal.openSweetAlertError(error.message);
      });
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  // validadorResponse(error: any) {
  //   if (error.status) {
  //     if (error.status === 403) {
  //       this.router.navigate(['/login']);
  //     } else if (error.status === 404) {
  //       this.messageSwal.opensweetalertwrg(error.error);
  //     }else {
  //       Swal.fire('Oops...', error.message, 'error')
  //     }
  //   } else {
  //     Swal.fire('Oops...', error.message, 'error')
  //   }
  // }

  public openEvent(stepNumb: number) {
    this.step = stepNumb;
  }

  public range(range: NumberSymbol) {
    this.page = 1;
    this.rangeRow = 10;
    this.step = 0;
    this.rangeRow = range;
  }

  public pageChanged(pageIndex: number) {
    this.page = pageIndex;
  }
  validate() {
    this.submittedEdit = true;
    if (this.creativeTypeUpateForm.invalid) {
      Swal.fire({ text: 'Fill in the mandatory fields.', timer: 1300, showCancelButton: false });
      return;
    }
  }

}

