import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from '../components/login/login.component';
import { DashboardComponent } from '../components/dashboard/dashboard.component';
import { CampaignComponent } from '../components/dashboard/campaign/campaign.component';
import { CountriesComponent } from '../components/dashboard/countries/countries.component';
import { AgencyComponent } from '../components/dashboard/agency/agency.component';
import { CampaignAdministratorComponent } from '../components/dashboard/campaign-administrator/campaign-administrator.component';
import { CampaignUpdateComponent } from '../components/dashboard/campaign-administrator/campaign-update/campaign-update.component';
import { ObjectivesComponent } from '../components/dashboard/objectives/objectives.component';
import { SiteComponent } from '../components/dashboard/site/site.component';
import { QuarterComponent } from '../components/dashboard/quarter/quarter.component';
import { FiscalYearComponent } from '../components/dashboard/fiscal-year/fiscal-year.component';
import { CreativeTypeComponent } from '../components/dashboard/creative-type/creative-type.component';
import { UtmSourceComponent } from '../components/dashboard/utm-source/utm-source.component';
import { UtmMediumComponent } from '../components/dashboard/utm-medium/utm-medium.component';
import { UtmContentComponent } from '../components/dashboard/utm-content/utm-content.component';
import { AuthGuard } from '../services/auth.guard';
import { UsersComponent } from '../components/dashboard/users/users.component';
import { UserAddComponent } from '../components/dashboard/users/user-add/user-add.component';
import { UserEditComponent } from '../components/dashboard/users/user-edit/user-edit.component';
import { ResetPasswordComponent } from '../components/reset-password/reset-password.component';
import { NewPasswordComponent } from '../components/new-password/new-password.component';

const routes: Routes = [
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    children: [
      { path: 'campaign', component: CampaignComponent},
      { path: 'campaign_administrator', component: CampaignAdministratorComponent},
      { path: 'campaign_edit', component: CampaignUpdateComponent},
      { path: 'country_code', component: CountriesComponent},
      { path: 'agency', component: AgencyComponent},
      { path: 'objective', component: ObjectivesComponent},
      { path: 'channel', component: SiteComponent},
      { path: 'quarter', component: QuarterComponent},
      { path: 'fiscal-year', component: FiscalYearComponent},
      { path: 'creative-type', component: CreativeTypeComponent},
      { path: 'utm-sources', component: UtmSourceComponent},
      { path: 'utm-mediums', component: UtmMediumComponent},
      { path: 'utm-contents', component: UtmContentComponent},
      { path: 'users', component: UsersComponent},
      { path: 'user-add', component: UserAddComponent},
      { path: 'user-edit', component: UserEditComponent},
    ]
  },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: 'action', component: NewPasswordComponent },

  { path: '**', component: LoginComponent },
  { path: 'login', component: LoginComponent },
 ];
  


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
