import { NumberSymbol } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, } from '@angular/forms';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { CampaignService } from 'src/app/services/campaign-services/campaign.service';
import { ClipboardService } from 'ngx-clipboard';
import { CountriesManagerService } from 'src/app/services/countries-services/countries-manager.service';
import { QuarterManagerService } from 'src/app/services/quarter-services/quarter-manager.service';
import { FiscalYearManagerService } from 'src/app/services/fiscal-year-services/fiscalYear-manager.service';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { ObjectiveManager } from 'src/app/services/objective/objective-manager';
import { CustomDateParserFormatter } from 'src/app/services/helpers/NgbDateCustomParserFormatter ';
import { SiteManagerService } from 'src/app/services/site-services/site-manager.service';
import { CreativeTypeManagerService } from 'src/app/services/creativeType-services/creativeType-manager.service';
import { UtmSourceManagerService } from 'src/app/services/utmSources-services/utmSource-manager.service';
import { UtmContentManagerService } from 'src/app/services/utmContent-services/utmContent-manager.service';
import { GeneralServiceMessages } from 'src/app/services/messages-services/utilities-message';
import { find } from 'rxjs/operators';



@Component({
  selector: 'app-campaign',
  templateUrl: './campaign.component.html',
  styleUrls: ['./campaign.component.css'],
  providers: [
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
  ]

})
export class CampaignComponent implements OnInit {
  public campaignForm!: FormGroup;
  public campaignEditarForm!: FormGroup;
  public submitted = false;
  public submittedEdit = false;
  public countries: any[] = [];
  public quarters: any[] = [];
  public fiscalYears: any[] = [];
  public sites: any[] = [];
  public objectives: any[] = [];
  public creativeTypes: any[] = [];
  public utmSources: any[] = [];
  public utmMediums: any[] = [];
  public utmContents: any[] = [];  
  public sourcePlatform: any[] = [];

  statusCheck: boolean = true;
  private id_ramdom = '';
  public campaign_text: string = '';
  private token = '';
  public total = 0;
  public page = 1;
  public rangeRow = 10;
  public step = 0;
  public loading = false;
  public closeModal: string = '';
  public callService = false;
  public isCorrect = false;
  public modelInicio: NgbDateStruct | undefined;
  public modelFin: NgbDateStruct | undefined;
  public labelStatus!: string;
  public isLinear = false;
  public hiddenUtm = false;
  constructor(
    private formBuilder: FormBuilder,
    private campaignService: CampaignService,
    private countriesManager: CountriesManagerService,
    private quarterManager: QuarterManagerService,
    private fiscalYearManager: FiscalYearManagerService,
    private siteManager: SiteManagerService,
    private cretiveTypesManager: CreativeTypeManagerService,
    private objectiveManager: ObjectiveManager,
    private utmSourceManager: UtmSourceManagerService,
    private utmContentManager: UtmContentManagerService,
    private clipboardApi: ClipboardService,
    private messageSwal: GeneralServiceMessages,
    private router: Router) {

    this.initializeData();
    
  }
  ngOnInit(): void {
    this.campaignForm = this.formBuilder.group({
      countryNameCtrl: ['', Validators.required],
      siteCtrl: ['', Validators.required],
      objectiveCtrl: ['', Validators.required],
      nameCampaignCtrl: ['', Validators.required],
      quarterCtrl: ['', Validators.required],
      fiscalYearCtrl: ['', Validators.required],
      rotationCtrl: ['50', Validators.required],
      startDateCtrl: ['', Validators.required],
      endDateCtrl: ['', Validators.required],
      urlPageCtrl: ['', Validators.required],
      statusCtrl: ['Active', Validators.required],
      utmSourceCtrl: ['', Validators.required],
      utmCampaignCtrl: ['', Validators.required],
      utmMediumCtrl: ['', Validators.required],
      utmIdCtrl: ['', Validators.required],
      sourcePlatformCtrl: ['', Validators.required],
      utmContentCtrl: ['', Validators.required],
      utmGeneratedCtrl: ['', Validators.required],
      creativeTypeCtrl: ['', Validators.required],
      bugetCtrl: ['', Validators.required],
    });
  }

  get f() {
    return this.campaignForm.controls;
  }

  createCampaign() {
    this.loading = true;
    this.submitted = true;
    if (this.campaignForm.invalid) {
      this.loading = false;
      return;
    } else {
      this.callService = true;
      const countryCode = this.campaignForm.controls['countryNameCtrl'].value;
      const campaign_name = this.campaignForm.controls['nameCampaignCtrl'].value;
      const site = this.campaignForm.controls['siteCtrl'].value;
      const objective = this.campaignForm.controls['objectiveCtrl'].value;
      const quarter = this.campaignForm.controls['quarterCtrl'].value;
      const fiscalYear = this.campaignForm.controls['fiscalYearCtrl'].value;
      const creative_type = this.campaignForm.controls['creativeTypeCtrl'].value;
      const start_date: string = this.campaignForm.controls['startDateCtrl'].value;
      const end_date: string = this.campaignForm.controls['endDateCtrl'].value;
      const linking_url = this.campaignForm.controls['urlPageCtrl'].value;
      const rotation = this.campaignForm.controls['rotationCtrl'].value;
      const status = this.statusCheck;
      const utm_source = this.campaignForm.controls['utmSourceCtrl'].value;
      const utm_medium = this.campaignForm.controls['utmMediumCtrl'].value;
      const source_platform = this.campaignForm.controls['sourcePlatformCtrl'].value;
      const utm_campaign = this.campaignForm.controls['utmCampaignCtrl'].value;
      const id_ramdom = this.id_ramdom;
      const utm_content = this.campaignForm.controls['utmContentCtrl'].value;
      const utm_generated = this.campaignForm.controls['utmGeneratedCtrl'].value;
      const utm_id = this.campaignForm.controls['utmIdCtrl'].value;
      const buget = this.campaignForm.controls['bugetCtrl'].value;


      this.submitted = true;
      this.callService = false;
      this.isCorrect = true;
      const info = {
        market_id: countryCode,
        campaing_name: campaign_name,
        site_id: site,
        objective_id: objective,
        quarter_id: quarter,
        fiscal_year_id: fiscalYear,
        creative_type_id: creative_type,
        creative_start_date: start_date,
        creative_end_date: end_date,
        linking_url: linking_url,
        rotation: rotation,
        status: status,
        utm_source_id: utm_source,
        utm_medium_id: utm_medium,
        utm_source_platform: source_platform,
        utm_campaign: utm_campaign,
        random_id: id_ramdom,
        utm_content_id: utm_content,
        utm_generated: utm_generated,
        utm_id: utm_id,
        buget: buget

      }
      this.campaignService.createCampaign(info).subscribe(response => {
        this.loading = false;
        this.messageSwal.opensweetalertWithThen('Campaign successful registration', () => { window.location.reload() });
      }, error => {
        this.loading = false;
        this.validadorResponse(error);
      });
    }
  }

  copyToClipboard() {
    this.clipboardApi.copyFromContent(this.campaign_text || '');
    Swal.fire({ text: 'The Campaign was copied to the clipboard.', timer: 500, showCancelButton: false });
  }

  async initializeData(): Promise<void> {
    try {
      this.countriesManager.getCountries().then((country: any[]) => {
        this.countries = country;
      });
      this.quarterManager.getQuarter().then((quarter: any[]) => {
        this.quarters = quarter;
      });
      this.fiscalYearManager.getFiscalYear().then((fiscalYear: any[]) => {
        this.fiscalYears = fiscalYear;
      });
      this.siteManager.getSite().then((site: any[]) => {
        this.sites = site;
      });
      this.objectiveManager.getObjective().then((objective: any[]) => {
        this.objectives = objective;
      });
      this.cretiveTypesManager.getCreativeType().then((creativeType: any[]) => {
        this.creativeTypes = creativeType;
      });
      this.utmContentManager.getUtmContent().then((utm_content: any[]) => {
        this.utmContents = utm_content;
      });
      this.utmSourceManager.getUtmSource().then((utm_source: any[]) => {
        this.sourcePlatform = utm_source;
      });

    } catch (error) {

    }
  }

  loadSourcesAndMediums(event: Event) {
    const channel_id = this.campaignForm.controls.siteCtrl.value;
    const select_channel_sources = this.sites?.find((item: any) => parseInt(item.id) === parseInt(channel_id));
    this.utmSources = select_channel_sources.UtmSourceModels

    const select_channel_medium = this.sites?.find((item: any) => parseInt(item.id) === parseInt(channel_id));
    this.utmMediums = select_channel_medium.UtmMediumModels
  }
  validadorResponse(error: any) {
    if (error.status) {
      if (error.status === 403) {
        this.router.navigate(['/login']);
      } else {
        Swal.fire('Oops...', error.message, 'error')
      }
    } else {
      Swal.fire('Oops...', error.message, 'error')
    }
  }

  public openEvent(stepNumb: number) {
    this.step = stepNumb;
  }

  public range(range: NumberSymbol) {
    this.page = 1;
    this.rangeRow = 10;
    this.step = 0;
    this.rangeRow = range;
  }

  public pageChanged(pageIndex: number) {
    this.page = pageIndex;
  }

  validate() {
    this.submittedEdit = true;
    if (this.campaignEditarForm.invalid) {
      Swal.fire({ text: 'Fill in the mandatory fields.', timer: 1300, showCancelButton: false });
      return;
    }
  }
  changeConversion(event: any) {
    let startDate = this.campaignForm.controls.startDateCtrl.value || '';
    let endDate = this.campaignForm.controls.endDateCtrl.value || '';
    if (startDate != '') {
      startDate = `${(startDate.month < 10) ? '0' + startDate.month : startDate.month}${(startDate.day < 10) ? '0' + startDate.day : startDate.day}${startDate.year.toString().substr(-2)}`;
    }
    if (endDate != '') {
      endDate = `${(endDate.month < 10) ? '0' + endDate.month : endDate.month}${(endDate.day < 10) ? '0' + endDate.day : endDate.day}${endDate.year.toString().substr(-2)}`;
    }
    const info = `${startDate}-${endDate}`;
  }
  checkboxChange() {
    if (this.statusCheck) {
      this.statusCheck = false;
    } else {
      this.statusCheck = true;
    }
  }

  generateUtmCampaign(event: Event) {
    const utmSourceValue = this.campaignForm.controls.utmSourceCtrl.value;
    const countryValue = this.campaignForm.controls.countryNameCtrl.value;
    if (utmSourceValue !== '' && countryValue !== '') {
      const countryCode = this.countries?.find(resp => parseInt(resp.id) === parseInt(countryValue));
      const utmSource = this.utmSources?.find(resp => parseInt(resp.id) === parseInt(utmSourceValue));
      const utm_campaign = `${countryCode.country_code}_${utmSource.utm_source_name}`;
      this.campaignForm.controls['utmCampaignCtrl'].setValue(utm_campaign.toLowerCase());
    }
  }

  generateUtmID(event: Event) {
    if (this.campaignForm.controls.utmMediumCtrl.value !== '') {
      const random_id = randomString();
      this.id_ramdom = random_id;
      const utmMedium = this.utmMediums?.find(resp => parseInt(resp.id) === parseInt(this.campaignForm.controls.utmMediumCtrl.value));
      const utm_id = `${utmMedium.utm_medium_name}_${random_id}`;
      this.campaignForm.controls['utmIdCtrl'].setValue(utm_id);
    }
  }

  generateUtm() {
    const linking_URL = this.campaignForm.controls.urlPageCtrl.value;
    const utm_source = this.utmSources?.find(resp => parseInt(resp.id) === parseInt(this.campaignForm.controls.utmSourceCtrl.value));
    const utm_medium = this.utmMediums?.find(resp => parseInt(resp.id) === parseInt(this.campaignForm.controls.utmMediumCtrl.value));
    const utm_source_platform = this.sourcePlatform?.find(resp => parseInt(resp.id) === parseInt(this.campaignForm.controls.sourcePlatformCtrl.value));
    const utm_content = this.utmContents?.find(resp => parseInt(resp.id) === parseInt(this.campaignForm.controls.utmContentCtrl.value));
    const utm_campaign = this.campaignForm.controls.utmCampaignCtrl.value;
    const utm_id = this.campaignForm.controls.utmIdCtrl.value;

    const utm_generated = `${linking_URL}&utm_source=${utm_source?.utm_source_name}&utm_medium=${utm_medium?.utm_medium_name}&utm_campaign=${utm_campaign}&utm_id=${utm_id}&utm_source_platform=${utm_source_platform.utm_source_name}&utm_content=${utm_content.utm_content_name}`;
    this.hiddenUtm = true;
    this.campaignForm.controls['utmGeneratedCtrl'].setValue(utm_generated.toLowerCase());
  }

  isValidNameCampaign(): boolean {
    const text_ncampign = this.campaignForm.controls.nameCampaignCtrl.value
    if (text_ncampign && text_ncampign.includes(' ')) {
      this.campaignForm.controls.nameCampaignCtrl.setErrors({ spacesText: true });
      return true
    } else {
      return false;
    }
  }
}
function randomString() {
  var p = "abcdefghijklmnopqrstuvwxyz0123456789";
  return [...Array(7)].reduce(a => a + p[~~(Math.random() * p.length)], '');
}

