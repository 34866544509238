import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { AuthSerices } from 'src/app/services/auth-services/auth-api.service';
import { GeneralServiceMessages } from 'src/app/services/messages-services/utilities-message';
import { StorageService } from 'src/app/services/storage.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  public loginForm!: FormGroup;
  public isSubmitted = false;
  public loggedIn = new BehaviorSubject<boolean>(false);
  public loading = false;
  public password: string = '';

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private storageService: StorageService,
    private authSerices: AuthSerices,
    private messageSweet: GeneralServiceMessages
  ) {

  }
  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });
  }
  get formControls() {
    return this.loginForm.controls;
  }

  login() {
    this.isSubmitted = true;
    if (this.loginForm.invalid) {
      return;
    } else {
      this.loading = true;
      const email = this.loginForm.controls['email'].value;
      const password = this.loginForm.controls['password'].value;

      this.authSerices.autenticar(email, password).subscribe((response: any) => {
        if (response.status) {
          this.storageService.set('profiles', JSON.stringify(response));
          this.loading = false;
          this.router.navigate(['/dashboard/campaign']);
        } else {
          this.loading = false;
          this.messageSweet.openSweetAlertError(response.message);
        }
      }, error => {
        this.loading = false;
        this.messageSweet.openSweetAlertError(error.error.message);
      }

      );

    }
  }

}
