import { Injectable } from '@angular/core';
import { CampaignService } from './campaign.service';
import { Router } from '@angular/router';
import { GeneralServiceMessages } from '../messages-services/utilities-message';

@Injectable({
  providedIn: 'root'
})
export class CampaignManagerService {
  public loading = false;
  public token = '';

  constructor(
    private campaignServices: CampaignService,
    private router: Router,
    private messageSweet: GeneralServiceMessages
  ) {
    this.token = sessionStorage.getItem('data') || '';
  }

  getCampaigns(): Promise<any[]> {
    this.loading = true;
    return new Promise<any[]>((resolve, reject) => {
      this.campaignServices.getCampaigns(this.token).subscribe(
        response => {
          resolve(response.data);
        },
        error => {
          this.loading = false;
          reject([]);
        }
      );
    });
  }
  getCampaignById(id : string): Promise<any[]> {
    this.loading = true;
    return new Promise<any[]>((resolve, reject) => {
      this.campaignServices.getCampaignById(id).subscribe(
        response => {
          resolve(response.data);
        },
        error => {
          this.loading = false;
          reject([]);
        }
      );
    });
  }

}