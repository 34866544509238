
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { QuarterManagerService } from 'src/app/services/quarter-services/quarter-manager.service';
import Swal from 'sweetalert2';
import { NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NumberSymbol } from '@angular/common';
import jwt_decode from 'jwt-decode';
import { Camelize } from 'src/app/services/helpers/stringCamelCaseValidator.validator';
import { QuarterApiService } from 'src/app/services/quarter-services/quarter-api';
import { GeneralServiceMessages } from 'src/app/services/messages-services/utilities-message';
@Component({
  selector: 'app-quarter',
  templateUrl: './quarter.component.html',
  styleUrls: ['./quarter.component.css']
})
export class QuarterComponent implements OnInit {

  public quarterCreateForm!: FormGroup;
  public quarterUpateForm!: FormGroup;
  public quarters: any[] = [];
  private token = '';
  public total = 0;
  public page = 1;
  public rangeRow = 10;
  public step = 0;
  public loading = false;
  public submitted = false;
  public submittedEdit = false;
  public closeModal: string = '';
  public modalRef!: NgbModalRef;

  constructor(
    private formBuilder: FormBuilder,
    private quarterManager: QuarterManagerService,
    private router: Router,
    private modalServices: NgbModal,
    private quarterService: QuarterApiService,
    private messageSwal: GeneralServiceMessages,
  ) {
    // const response = sessionStorage.getItem('data');
    // if (response) {
    //   this.token = sessionStorage.getItem('data') || '';
    //   const decoded: any = jwt_decode(response);
    //   if (decoded.perfil !== 'administrator') {
    //     this.router.navigate(['/login']);
    //   }
      this.initializedData()
    // } else {
    //   this.router.navigate(['/login']);
    // }
  }

  ngOnInit(): void {
    this.quarterCreateForm = this.formBuilder.group({
      nameQuarterCtrl: ['', Validators.required],
    });

    this.quarterUpateForm = this.formBuilder.group({
      nameQuarterUpdateCtrl: ['', Validators.required],

    });
  }
  get formUpdate() {
    return this.quarterUpateForm.controls;
  }
  get formCreate() {
    return this.quarterCreateForm.controls;
  }
  initializedData() {
    this.page = 1;
    this.rangeRow = 10;
    this.step = 0;
    this.quarters = [];
    this.total = 0;
    this.loading = true;
    this.quarterManager.getQuarter().then((quarter: any[]) => {
      this.quarters = quarter;
      this.total = this.quarters.length;

    });
  }
  openModalCreate(modalDataCreate: any) {
    this.modalRef = this.modalServices.open(modalDataCreate, { ariaLabelledBy: 'modal-dialog modal-dialog-centered' });
  }
  createQuarter() {
    this.submitted = true;
    if (this.quarterCreateForm.invalid) {
      return;
    } else {
      this.loading = true;
      let name_quarter = this.quarterCreateForm.controls.nameQuarterCtrl.value || '';
      name_quarter = Camelize(name_quarter);
      this.quarterService.createQuarter(name_quarter, this.token).subscribe(response => {
        this.initializedData();
        this.modalRef.close();
        this.messageSwal.opensweetalert('Quarter successful update');
      }, error => {
        this.loading = false;
        this.messageSwal.validadorResponse(error);
      });
    }
  }

  updateQuarter(item: any, modalData: any) {
    const idQuarter = item.id
    this.submittedEdit = true;
    this.quarterUpateForm.controls['nameQuarterUpdateCtrl'].setValue(item.name_quarter);
    this.modalServices.open(modalData, { ariaLabelledBy: 'modal-basic-title' }).result.then((res) => {
      if (res === 'false') {
        return;
      }
      if (this.quarterUpateForm.invalid) {
        Swal.fire({ text: 'Todos los campos yo obligatorios.', timer: 500, showCancelButton: false });
        return;
      } else {
        this.loading = true;
        let nameQuarter = this.quarterUpateForm.controls.nameQuarterUpdateCtrl.value || '';
        nameQuarter = Camelize(nameQuarter);
        this.quarterService.updateQuarter(idQuarter, nameQuarter, this.token).subscribe(res => {
          this.initializedData();
          this.messageSwal.opensweetalert('Quarter successful update');
        }, error => {
          this.loading = false;
          this.messageSwal.validadorResponse(error);
        })
      }
    }, (res) => {
      this.closeModal = `Dismissed ${this.getDismissReason(res)}`;
    });
  }

  deleteQuarter(id: string) {
    this.messageSwal.openSweetAlertDelete(() => { this.loading = true; }, () => {
      this.quarterService.deleteQuarter(id, '').subscribe(item => {
        this.loading = false;
        this.messageSwal.opensweetalertWithThen(item.message.charAt(0).toUpperCase() + item.message.slice(1),
          () => { this.initializedData(); });
      }, error => {
        this.loading = false;
        this.messageSwal.openSweetAlertError(error.message);
      });
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  public openEvent(stepNumb: number) {
    this.step = stepNumb;
  }

  public range(range: NumberSymbol) {
    this.page = 1;
    this.rangeRow = 10;
    this.step = 0;
    this.rangeRow = range;
  }

  public pageChanged(pageIndex: number) {
    this.page = pageIndex;
  }
  validate() {
    this.submittedEdit = true;
    if (this.quarterUpateForm.invalid) {
      Swal.fire({ text: 'Fill in the mandatory fields.', timer: 1300, showCancelButton: false });
      return;
    }
  }

}

