<nav class="navbar navbar-dark bg-custom">
  <div class="container-fluid col-xl-12 col-l-12 col-md-12 col-sm-12">
    <a class="navbar-brand puntero" (click)="navigationAction('/dashboard/campaign')"><img
        src="../assets/direcly_white.png" class="logo img-fluid img-responsive" /></a>
    <ul class="navbar-nav mr-auto ">
      <li class="nav-item active" *ngIf="campaignMenu">
        <a class="nav-link puntero text-light" aria-current="page" (click)="navigationAction('/dashboard/campaign')">New
          Campaign Name</a>
      </li>
    </ul>
    <a class="navbar-brand d-none d-md-block " href="/dashboard/campaign"><i class="bi bi-clipboard-data"> Planning Tool</i>
    </a>

    <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasDarkNavbar"
      aria-controls="offcanvasDarkNavbar" aria-label="Toggle navigation">
      <i class="bi bi-list"></i>
    </button>

    <div class="offcanvas offcanvas-end text-bg-dark" tabindex="-1" id="offcanvasDarkNavbar"
      aria-labelledby="offcanvasDarkNavbarLabel" [ngbCollapse]="isCollapsed">

      <div class="offcanvas-header">

        <h4 class="offcanvas-title" id="offcanvasDarkNavbarLabel"> <i class="bi bi-person"> {{user}}</i> </h4>
        <button type="button" class="btn-close btn-close-white" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      </div>

      <div class="offcanvas-body">
        <ul class="navbar-nav justify-content-end flex-grow-1 pe-3">
          <li class="nav-item" data-bs-dismiss="offcanvas">
            <a class="nav-link active" aria-current="page"
              (click)="navigationAction('/dashboard/campaign_administrator')" *ngIf="optionsMenu">
              UTM Generated</a>
          </li>
          <!-- <h4 class="offcanvas-title" id="offcanvasDarkNavbarLabel"> <i class="bi bi-gear"> Administration</i> </h4> -->

          <li class="nav-item" data-bs-dismiss="offcanvas">
            <a class="nav-link Link" (click)="navigationAction('/dashboard/channel')" *ngIf="optionsMenu">Channels</a>
          </li>
          <li class="nav-item" data-bs-dismiss="offcanvas">
            <a class="nav-link Link" (click)="navigationAction('/dashboard/creative-type')" *ngIf="optionsMenu">Creative
              type</a>
          </li>
          <li class="nav-item" data-bs-dismiss="offcanvas">
            <a class="nav-link Link" (click)="navigationAction('/dashboard/fiscal-year')" *ngIf="optionsMenu">Fiscal
              years</a>
          </li>
          <li class="nav-item" data-bs-dismiss="offcanvas">
            <a class="nav-link Link" aria-current="page" (click)="navigationAction('/dashboard/country_code')"
              *ngIf="optionsMenu">
              Markets</a>
          </li>
          <li class="nav-item" data-bs-dismiss="offcanvas">
            <a class="nav-link Link" (click)="navigationAction('/dashboard/objective')"
              *ngIf="optionsMenu">Objectives</a>
          </li>
          <li class="nav-item" data-bs-dismiss="offcanvas">
            <a class="nav-link Link" (click)="navigationAction('/dashboard/quarter')" *ngIf="optionsMenu">Quarters</a>
          </li>
          <li class="nav-item" data-bs-dismiss="offcanvas">
            <a class="nav-link Link" (click)="navigationAction('/dashboard/utm-sources')" *ngIf="optionsMenu">Utm
              sources</a>
          </li>
          <li class="nav-item" data-bs-dismiss="offcanvas">
            <a class="nav-link Link" (click)="navigationAction('/dashboard/utm-mediums')" *ngIf="optionsMenu">Utm
              mediums</a>
          </li>
          <li class="nav-item" data-bs-dismiss="offcanvas">
            <a class="nav-link Link" (click)="navigationAction('/dashboard/utm-contents')" *ngIf="optionsMenu">Utm
              contents</a>
          </li>
          <li class="nav-item" data-bs-dismiss="offcanvas">
            <a class="nav-link Link" (click)="navigationAction('/dashboard/users')" *ngIf="optionsMenu">Users</a>
          </li>
        </ul>

      </div>
      <div class="offcanvas-footer ">
        <ul class="navbar-nav justify-content-end flex-grow-1 pe-3">
          <li class="nav-item custom-sign">
            <a class="nav-link active text-center " aria-current="page" href="#" (click)="cerrarSesion()"><i
                class="bi bi-box-arrow-right "> Sign
                off</i></a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</nav>
<div class="container">
  <div class="row">
    <div class="container-fluid">
      <main role="main" class="col-md-12">
        <router-outlet></router-outlet>
      </main>
    </div>
  </div>
</div>