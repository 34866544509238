import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FiscalYearApiService {

  constructor(private http: HttpClient) {
  }
  getFiscalYear(): Observable<any> {
    return this.http.get(environment.urlservices + '/fiscal-years',)
  }
  createFiscalYear(name_fiscal_year: string): Observable<any> {
    const body = {
      name_fiscal_year
    };
    return this.http.post(environment.urlservices + '/fiscal-years', body,)
  }

  deleteFiscalYear(id: string, token: string): Observable<any> {
    return this.http.delete(`${environment.urlservices}/fiscal-years/${id}`,)
  }

  updateFiscalYear(id: string, name: string, token: string): Observable<any> {
    const body = {
      fiscal_year_id: id,
      name_fiscal_year: name
    };
    return this.http.put(`${environment.urlservices}/fiscal-years/${id}`, body,)
  }
}