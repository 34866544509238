import { Component, OnInit } from '@angular/core';
import { NumberSymbol } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CountriesManagerService } from 'src/app/services/countries-services/countries-manager.service';
import { Camelize } from 'src/app/services/helpers/stringCamelCaseValidator.validator';
import Swal from 'sweetalert2';


import jwt_decode from 'jwt-decode';
import { CountriesApiService } from 'src/app/services/countries-services/countries-api';
import { GeneralServiceMessages } from 'src/app/services/messages-services/utilities-message';


@Component({
  selector: 'app-countries',
  templateUrl: './countries.component.html',
  styleUrls: ['./countries.component.css']
})
export class CountriesComponent implements OnInit {
  public countryCodeForm!: FormGroup;
  public countryCodeEditarForm!: FormGroup;
  public submitted = false;
  public submittedEdit = false;
  public countryCodes: any[] = [];
  private token = '';
  public total = 0;
  public page = 1;
  public rangeRow = 10;
  public step = 0;
  public loading = false;
  public closeModal: string = '';
  modalRef!: NgbModalRef;


  constructor(
    private formBuilder: FormBuilder,
    private countryCodeManager: CountriesManagerService,
    private countryCodeService: CountriesApiService,
    private router: Router,
    private modalServices: NgbModal,
    private messageSwal: GeneralServiceMessages,

  ) {

    this.initializedData()

  }

  ngOnInit(): void {
    this.countryCodeForm = this.formBuilder.group({
      countryCtrl: ['', Validators.required],
      countryCodeCtrl: ['', Validators.required]
    });

    this.countryCodeEditarForm = this.formBuilder.group({
      countryEditCtrl: ['', Validators.required],
      countryCodeEditCtrl: ['', Validators.required]
    });
  }
  initializedData() {
    this.page = 1;
    this.rangeRow = 10;
    this.step = 0;
    this.countryCodes = [];
    this.total = 0;
    this.loading = true;
    this.countryCodeManager.getCountries().then((countries: any[]) => {
      this.countryCodes = countries;
      this.total = this.countryCodes.length;

    });
  }
  get formCreate() {
    return this.countryCodeForm.controls;
  }

  get formEdit() {
    return this.countryCodeEditarForm.controls;
  }

  opeModalCreate(modalDataCreate: any) {
    this.modalRef = this.modalServices.open(modalDataCreate, { ariaLabelledBy: 'modal-dialog modal-dialog-centered' });
  }

  createCountryCode() {
    this.submitted = true;
    if (this.countryCodeForm.invalid) {
      return;
    } else {
      this.loading = true;
      let nameCountryCtrl = this.countryCodeForm.controls.countryCtrl.value || '';
      let countryCodeCtrl = this.countryCodeForm.controls.countryCodeCtrl.value || '';
      nameCountryCtrl = Camelize(nameCountryCtrl);
      countryCodeCtrl = Camelize(countryCodeCtrl);
      const countryAdd = { name_country: nameCountryCtrl, country_code: countryCodeCtrl };
      this.countryCodeService.createCountryCode(countryAdd).subscribe(response => {
        this.loading = false;
        this.initializedData();
        this.modalRef.close();
        this.messageSwal.opensweetalert('Market successful update');
      }, error => {
        this.loading = false;
        this.messageSwal.validadorResponse(error);
      });
    }
  }

  editCountryCode(item: any, modalData: any) {
    const idcountryCode = item.id
    this.submittedEdit = true;
    this.countryCodeEditarForm.controls['countryEditCtrl'].setValue(item.name_country);
    this.countryCodeEditarForm.controls['countryCodeEditCtrl'].setValue(item.country_code);
    this.modalServices.open(modalData, { ariaLabelledBy: 'modal-basic-title' }).result.then((res) => {
      if (res === 'false') {
        return;
      }
      if (this.countryCodeEditarForm.invalid) {
        Swal.fire({ text: 'Todos los campos yo obligatorios.', timer: 500, showCancelButton: false });
        return;
      } else {
        this.loading = true;
        let nameCountryNew = this.countryCodeEditarForm.controls.countryEditCtrl.value || '';
        let countryCodeNew = this.countryCodeEditarForm.controls.countryCodeEditCtrl.value || '';
        nameCountryNew = Camelize(nameCountryNew);
        countryCodeNew = Camelize(countryCodeNew);
        this.countryCodeService.editCountryCode(nameCountryNew, countryCodeNew, idcountryCode, this.token).subscribe(response => {

          this.initializedData();
          this.messageSwal.opensweetalert('Market successful update');

        }, error => {
          this.loading = false;
          this.messageSwal.validadorResponse(error);
        });
      }
    }, (res) => {
      this.closeModal = `Dismissed ${this.getDismissReason(res)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  deleteCountryCode(id: any) {
    this.messageSwal.openSweetAlertDelete(() => { this.loading = true; }, () => {
      this.countryCodeService.deleteCountryCode(id, '').subscribe(item => {
        this.loading = false;
        this.messageSwal.opensweetalertWithThen(item.message.charAt(0).toUpperCase() + item.message.slice(1), () => { this.initializedData(); });
      }, error => {
        this.loading = false;
        this.messageSwal.openSweetAlertError(error.message);
      });
    });

  }
  public openEvent(stepNumb: number) {
    this.step = stepNumb;
  }

  public range(range: NumberSymbol) {
    this.page = 1;
    this.rangeRow = 10;
    this.step = 0;
    this.rangeRow = range;
  }

  public pageChanged(pageIndex: number) {
    this.page = pageIndex;
  }
  validate() {
    this.submittedEdit = true;
    if (this.countryCodeEditarForm.invalid) {
      Swal.fire({ text: 'Fill in the mandatory fields.', timer: 1300, showCancelButton: false });
      return;
    }
  }

}
