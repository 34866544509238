import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CampaignService {

  constructor(private http: HttpClient) {
  }

  getCampaigns(token: string): Observable<any> {
    return this.http.get(environment.urlservices + '/campaign-utm')
  }

  getCampaignById(id: string): Observable<any> {
    return this.http.get(`${environment.urlservices}/campaign-utm/${id}`)
  }

  createCampaign(info: any): Observable<any> {
    const body = JSON.stringify({
      info_campaign: info
    });
    return this.http.post(`${environment.urlservices}/campaign-utm/add-campaign` , info)
  }

  deleteCampaign(id: string): Observable<any> {
    return this.http.delete(`${environment.urlservices}/campaign-utm/${id}`)
  }

  updateCampaign(id : any, info: any): Observable<any> {
    const body = JSON.stringify({
      info_campaign: info
    });
    return this.http.put(`${environment.urlservices}/campaign-utm/${id}`, info)
  }
}