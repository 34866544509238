<ngx-loading [show]="loading" [config]="{fullScreenBackdrop: true}"></ngx-loading>
<div class="container">
    <div class="row">
        <div class="col-12">
            <br>
            <div class="container">
                <div class="row">
                    <div class="col-6">
                        <h2 class="h5">Channels</h2>
                    </div>
                    <div class="col-6 text-end">
                        <button type="submit" class="btn btn-primary  btnNet"
                            (click)="openModalCreate(modalDataCreate)">
                            <span><i class="bi bi-plus-circle-fill bi-size-3x"> Add channel</i></span>
                        </button>
                    </div>
                </div>
            </div>
            <label for="inputExchange">&nbsp;</label><br>
        </div>
        <div class="col-12">
            <div class="">
                <div class="item-wrapper">
                    <div class="table">
                        <table class="table table-striped table-hover">
                            <thead>
                                <tr>
                                    <th>Id channel</th>
                                    <th>Name channel</th>
                                    <th colspan="2">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    *ngFor="let item of sites | paginate: { itemsPerPage: rangeRow, currentPage: page , totalItems: total}; let i = index">
                                    <td>{{item.id}}</td>
                                    <td>{{item.name_site}}</td>
                                    <td>
                                        <a href="javascript: void(0);" (click)="updateSite(item, modalData)"><i
                                                class="bi bi-pencil-square text-dark"></i></a>
                                    </td>
                                    <td>
                                        <a href="javascript: void(0);" (click)="deleteSite(item.id)"><i
                                                class="bi bi-trash-fill text-dark"></i></a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="dataEmpty" *ngIf="sites.length < 1">
                            <p>No records found</p>
                        </div>
                        <div *ngIf="sites.length > 0" class="table-control">
                            <div class="container">
                                <div class="row">
                                    <div class="col-12 text-center">
                                        <pagination-controls (pageChange)="pageChanged(page=$event)" previousLabel=""
                                            nextLabel="">
                                        </pagination-controls>
                                    </div>
                                    <div class="col-12 text-end">
                                        <select class="mdb-select colorful-select dropdown-primary mt-2 hidden-md-down"
                                            [ngModel]="rangeRow" (ngModelChange)="range($event)">
                                            <option value="" disabled>Number of rows</option>
                                            <option value="10" selected>10</option>
                                            <option value="25">25</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #modalData let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Edit channel</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="siteUpateForm">
            <div class="form-row">
                <div class="col-xs-12 col-sm-12 col-md-12">
                    <label for="inputExchange">Name channel</label>
                    <input type="text" class="form-control" placeholder="Name channel" formControlName="nameSiteUpdateCtrl"
                        [ngClass]="{ 'is-invalid':formUpdate.nameSiteUpdateCtrl.errors && submittedEdit}">
                    <div *ngIf="formUpdate.nameSiteUpdateCtrl.errors?.required" class="invalid-feedback">
                        Name channel is required
                    </div>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-12">
                    <label for="inputExchange">Source</label>
                    <ng-multiselect-dropdown [placeholder]="'Sources'" [settings]="sourceSettings"
                        [data]="sourceData"
                        formControlName="sourceUpdateCtrl">
                    </ng-multiselect-dropdown>

                </div>

                <div class="col-xs-12 col-sm-12 col-md-12">
                    <label for="inputExchange">Medium</label>
                    <ng-multiselect-dropdown [placeholder]="'Mediums'" [settings]="mediumSettings"
                        [data]="mediumData" 
                        formControlName="mediumUpdateCtrl">
                    </ng-multiselect-dropdown>
                </div>

            </div>
        </form>
    </div>
    <div class="modal-footer text-center">
        <button type="button" class="btn btn-primary "
            (click)="!siteUpateForm.invalid ? modal.close() : validate()">Update Channel</button>
        <button type="button" class="btn btn-secondary" (click)="modal.close('false')">Cancel</button>
    </div>
</ng-template>

<ng-template #modalDataCreate let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Create Channel</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="siteCreateForm">
            <div class="form-row">
                <div class="col-xs-12 col-sm-12 col-md-12">
                    <label for="inputExchange">Name channel</label>
                    <input type="text" class="form-control" placeholder="Name channel" formControlName="nameSiteCtrl"
                        [ngClass]="{ 'is-invalid':formCreate.nameSiteCtrl.errors && submitted}">
                    <div *ngIf="formCreate.nameSiteCtrl.errors" class="invalid-feedback">
                        Name channel is required.
                    </div>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-12">
                    <label for="inputExchange">Source</label>
                    <ng-multiselect-dropdown [placeholder]="'Sources'" [settings]="sourceSettings"
                        [data]="sourceData" 
                        formControlName="sourceCtrl">
                    </ng-multiselect-dropdown>

                </div>
                <div class="col-xs-12 col-sm-12 col-md-12">
                    <label for="inputExchange">Mediums</label>
                    <ng-multiselect-dropdown [placeholder]="'Mediums'" [settings]="mediumSettings"
                        [data]="mediumData"
                        formControlName="mediumCtrl">
                    </ng-multiselect-dropdown>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer text-center">
        <button type="submit" class="btn btn-primary " (click)="createSite()">Create channel</button>
        <button type="button" class="btn btn-secondary" (click)="modal.close('false')">Cancel</button>
    </div>
</ng-template>