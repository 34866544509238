<ngx-loading [show]="loading" [config]="{fullScreenBackdrop: true}"></ngx-loading>
<div class="container">
    <div class="row">
        <div class="col-12">
            <br>
            <div class="container">
                <div class="row">
                    <div class="col-6">
                    <h2 class="h5">Markets</h2>
                </div>
                <div class="ms-auto col-xs-6 col-sm-6 col-md-6 text-center">

                    <button type="submit" class="btn btn-primary  btnNet" (click)="opeModalCreate(modalDataCreate)">
                        <span><i class="bi bi-plus-circle-fill bi-size-3x"> Add market </i></span>
                    </button>
                </div>
            </div>
            <label for="inputExchange">&nbsp;</label><br>
        </div>
        <div class="col-12">
            <div class="">
                <div class="item-wrapper">
                    <div class="table">
                        <table class="table table-striped table-hover">
                            <thead>
                                <tr>
                                    <th>Id market </th>
                                    <th>Name market</th>
                                    <th>Country code</th>
                                    <th colspan="2">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    *ngFor="let item of countryCodes | paginate: { itemsPerPage: rangeRow, currentPage: page , totalItems: total}; let i = index">
                                    <td>{{item.id}}</td>
                                    <td>{{item.name_country}}</td>
                                    <td>{{item.country_code}}</td>
                                    <td>
                                        <a href="javascript: void(0);" (click)="editCountryCode(item, modalData)"><i
                                                class="bi bi-pencil-square text-dark"></i></a>
                                    </td>
                                    <td>
                                        <a href="javascript: void(0);" (click)="deleteCountryCode(item.id)"><i
                                                class="bi bi-trash-fill text-dark"></i></a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="dataEmpty" *ngIf="countryCodes.length < 1">
                            <p>No records found</p>
                        </div>
                        <div *ngIf="countryCodes.length > 0" class="table-control">
                            <div class="container">
                                <div class="row">
                                    <div class="col-12 text-center">
                                        <pagination-controls (pageChange)="pageChanged(page=$event)" previousLabel=""
                                            nextLabel="">
                                        </pagination-controls>
                                    </div>
                                    <div class="col-12 text-end">
                                        <select class="mdb-select colorful-select dropdown-primary mt-2 hidden-md-down"
                                            [ngModel]="rangeRow" (ngModelChange)="range($event)">
                                            <option value="" disabled>Number of rows</option>
                                            <option value="10" selected>10</option>
                                            <option value="25">25</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #modalData let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Edit market </h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="countryCodeEditarForm">
            <div class="form-row">
                <div class="col-xs-12 col-sm-12 col-md-12">
                    <label for="inputExchange">Name market</label>
                    <input type="text" class="form-control" placeholder="Name market" formControlName="countryEditCtrl"
                        [ngClass]="{ 'is-invalid':formEdit.countryEditCtrl.errors && submittedEdit}">
                    <div *ngIf="formEdit.countryEditCtrl.errors?.required" class="invalid-feedback">
                        Name market is required
                    </div>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-12">
                    <label for="inputExchange">Country code</label>
                    <input type="text" class="form-control" placeholder="Country code"
                        formControlName="countryCodeEditCtrl"
                        [ngClass]="{ 'is-invalid':formEdit.countryCodeEditCtrl.errors && submittedEdit}">
                    <div *ngIf="formEdit.countryCodeEditCtrl.errors?.required" class="invalid-feedback">
                        Country code is required
                    </div>
                </div>

            </div>
        </form>
    </div>
    <div class="modal-footer text-center">
        <button type="button" class="btn btn-primary "
            (click)="!countryCodeEditarForm.invalid ? modal.close() : validate()">Update market
            </button>
        <button type="button" class="btn btn-secondary" (click)="modal.close('false')">Cancel</button>
    </div>
</ng-template>

<ng-template #modalDataCreate let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Create market </h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="countryCodeForm">
            <div class="form-row">
                <div class="col-xs-12 col-sm-12 col-md-12">
                    <label for="inputExchange">Name market</label>
                    <input type="text" class="form-control" placeholder="Name market" formControlName="countryCtrl"
                        [ngClass]="{ 'is-invalid':formCreate.countryCtrl.errors && submitted}">
                    <div *ngIf="formCreate.countryCtrl.errors" class="invalid-feedback">
                        Name market is required.
                    </div>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-12">
                    <label for="inputExchange">Country code</label>
                    <input type="text" class="form-control" placeholder="Country code" formControlName="countryCodeCtrl"
                        [ngClass]="{ 'is-invalid':formCreate.countryCodeCtrl.errors && submitted}">
                    <div *ngIf="formCreate.countryCodeCtrl.errors" class="invalid-feedback">
                        Country code is required.
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer text-center">
        <button type="submit" class="btn btn-primary " (click)="createCountryCode()">Create market </button>
        <button type="button" class="btn btn-secondary" (click)="modal.close('false')">Cancel</button>
    </div>
</ng-template>