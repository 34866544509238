import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CountriesApiService {

  constructor(private http: HttpClient) {
  }
  getCountryCode(token: string): Observable<any> {
    return this.http.get(environment.urlservices + '/countries')
  }

  createCountryCode(countryAdd: any): Observable<any> {
    const body = 
      countryAdd
    ;
    return this.http.post(`${environment.urlservices}/countries/country-add`, body)
  }

  deleteCountryCode(id: any, token: string): Observable<any> {
    return this.http.delete(`${environment.urlservices}/countries/${id}`)
  }
  editCountryCode(nameCountry: string, countryCode: string, id: string, token: string): Observable<any> {
    const body = {
      name_country: nameCountry,
      country_code: countryCode,
    };
    return this.http.put(`${environment.urlservices}/countries/${id}`, body)
  }
}