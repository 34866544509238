<div class="container-div">
    <div class="container-login100 text-center">
        <ngx-loading [show]="loading"
            [config]="{animationType: ngxLoadingAnimationTypes.cubeGrid, primaryColour: '#ffffff', backdropBorderRadius: '13px'}">
        </ngx-loading>
        <div class="col-12 text-end">
            <div class="col-6 ">
                <img src="../assets/logo_direcly.png" id="icon" class="login_icon" alt="User Icon" />
            </div>
        </div>

        <div class="col-lg-7 col-md-8 col-sm-9 col-12 mx-auto  form-wrapper" *ngIf="viewer === 'resetPassword'">
            <div class="login100-form-avatar">
            </div>
            <form [formGroup]="newPassForm" (ngSubmit)="newPassword()">
                <div class="form-group input-rounded"
                    [ngClass]="{ 'has-error': isSubmitted && formControls.password.errors }">
                    <input type="password" class="form-control" formControlName="password" placeholder="New Password">
                    <div *ngIf="(newPassForm.get('password')?.invalid && newPassForm.get('password')?.touched)">
                        <small *ngIf="newPassForm.get('password')?.errors?.required" class="text-danger">
                            Password is required</small>
                        <small *ngIf="newPassForm.get('password')?.errors?.pattern" class="text-danger">Please
                            provide a
                            valid Password</small>
                    </div>
                </div>
                <div class="form-group input-rounded">
                    <input type="password" class="form-control" placeholder="Confirm Password"
                        formControlName="repeatPassword" />
                </div>
                <div *ngIf="(newPassForm.get('repeatPassword')?.invalid && newPassForm.get('repeatPassword')?.touched)">
                    <small *ngIf="newPassForm.get('repeatPassword')?.errors?.required" class="text-danger">Confirm
                        Password is required</small>
                    <small *ngIf="newPassForm.get('repeatPassword')?.errors?.pattern" class="text-danger">Please
                        provide a valid Password</small>
                </div>
                <div class="form-inline">
                </div>
                <button type="submit" class="btn btn-primary btn-block btnNet"> Send New Password</button>
            </form>
            <a routerLink="/login" class="text-left pt-2 ml-1 white-color"><u>Go to Login</u></a>
            <br><br><br>
        </div>
        <div class="col-lg-7 col-md-8 col-sm-9 col-12 mx-auto  form-wrapper" *ngIf="viewer === 'verifyEmail'">
            <div class="login100-form-avatar">
            </div>
            <div class="row">
                <div class="col text-center">
                    <p>You must enter your email and your new password</p>
                </div>
            </div>
            <br>
            <form [formGroup]="resetPassForm" (ngSubmit)="resetPassword()"
                *ngIf="this.textValidate === 'Your account has been verified.'">
                <div class="form-group input-rounded">
                    <input type="email" class="form-control" formControlName="email" placeholder="Email">
                    <div *ngIf="(resetPassForm.get('email')?.invalid && resetPassForm.get('email')?.touched)">
                        <small *ngIf="resetPassForm.get('email')?.errors?.required" class="text-danger">Email is
                            required</small>
                    </div>
                </div>
                <div class="form-group input-rounded"
                    [ngClass]="{ 'has-error': isSubmitted && formControlsReset.passwordReset.errors }">
                    <input type="password" class="form-control" formControlName="passwordReset"
                        placeholder="New Password">
                    <div
                        *ngIf="(resetPassForm.get('passwordReset')?.invalid && resetPassForm.get('passwordReset')?.touched)">
                        <small *ngIf="resetPassForm.get('passwordReset')?.errors?.required" class="text-danger">Password
                            is
                            required</small>
                        <small *ngIf="resetPassForm.get('passwordReset')?.errors?.pattern" class="text-danger">Please
                            provide a valid Password</small>
                    </div>
                </div>
                <div class="form-group input-rounded">
                    <input type="password" class="form-control" placeholder="Confirm Password"
                        formControlName="repeatPasswordReset" />
                </div>
                <div
                    *ngIf="(resetPassForm.get('repeatPasswordReset')?.invalid && resetPassForm.get('repeatPasswordReset')?.touched)">
                    <small *ngIf="resetPassForm.get('repeatPasswordReset')?.errors?.required"
                        class="text-danger">Confirm
                        Password is required</small>
                    <small *ngIf="resetPassForm.get('repeatPasswordReset')?.errors?.pattern" class="text-danger">Please
                        provide a valid Password</small>
                </div>
                <div class="form-inline">
                </div>
                <button type="submit" class="btn btn-primary btn-block btnNet"
                    *ngIf="this.textValidate === 'Your account has been verified.'"> Send New Password</button>
            </form>
            <br>
            <button routerLink="/login" class="btn btn-primary btn-block btnNet"
                *ngIf="this.textValidate !== 'Your account has been verified.'">Ok</button>
        </div>

    </div>
</div>
<div class="auth_footer mx-auto">
    <p class="text-muted text-center">
    </p>
</div>