import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ngxLoadingAnimationTypes } from 'ngx-loading';
import { AuthSerices } from 'src/app/services/auth-services/auth-api.service';
import { GeneralServiceMessages } from 'src/app/services/messages-services/utilities-message';


@Component({
  selector: 'app-user-add',
  templateUrl: './user-add.component.html',
  styleUrls: ['./user-add.component.css']
})
export class UserAddComponent implements OnInit {
  public userForm!: FormGroup;
  public roles: Promise<any[]>;
  public submitted = false;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loading = false;

  constructor(private formBuilder: FormBuilder,
    private userService: AuthSerices,
    private messageSwal: GeneralServiceMessages,
    private router: Router,
  ) {

    const rolesArray =
      [{ name: 'administrator' }, { name: 'operation' }, { name: 'generator' }, { name: 'media_plan' }];

    this.roles = Promise.resolve(rolesArray);
  }

  ngOnInit(): void {
    this.userForm = this.formBuilder.group({
      fullnameCtrl: ['', Validators.required],
      emailCtrl: ['', Validators.required],
      perfilCtrl: ['', Validators.required],
    });
  }
  get f() {
    return this.userForm.controls;
  }
  async onSubmit() {

    this.submitted = true;
    if (this.userForm.invalid) {
      return;
    } else {
      this.loading = true;
      let emailAdd = this.userForm.controls.emailCtrl.value || '';
      let fullname = this.userForm.controls.fullnameCtrl.value || '';
      let perfil = this.userForm.controls.perfilCtrl.value || '';
      this.userService.createUser(emailAdd, fullname, perfil).subscribe(result => {
        if (result.errno && result.errno === 1062) {
          this.messageSwal.openSweetAlertError(`The user with the email address ${emailAdd} already exists`);
        } else {
          const email = emailAdd;
          const action = 'verifyEmail';
          this.messageSwal.opensweetalert('User successful registration')
          this.userService.validateStatusEmailChangePassword({ email, action }).subscribe();
          this.router.navigate(['/dashboard/users']);
        }
      }, error => {
        this.loading = false;
        this.messageSwal.openSweetAlertError(error);
      });
    }
  }


}
