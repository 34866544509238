<div class="container-div col-xl-12 col-l-12 col-md-12 col-sm-12">
    <div class="container-login100 text-center">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <img src="../assets/logo_direcly.png" id="icon" class="login_icon" alt="User Icon" />
                    <h4>Enter your Credentials</h4>
                </div>
                <div class="form-group col-12">
                    <form [formGroup]="loginForm" (ngSubmit)="login()">
                        <div class="form-group input-rounded col-12">
                            <span class="input-group-addon"><i class="fa fa-user ti-user"></i></span>
                            <input type="email" [email]="true" class="form-control" placeholder="Email"
                                formControlName="email"
                                [ngClass]="{ 'is-invalid':formControls.email.errors && isSubmitted}">
                            <div *ngIf="formControls.email?.errors?.required" class="invalid-feedback">
                                Enter your email
                            </div>
                            <div *ngIf="formControls.email?.errors?.email" class="invalid-feedback">
                                Enter valid email
                            </div>
                        </div>
                        <!-- </div> -->
                        <!-- <div class="form-group"> -->
                        <div class="form-group input-rounded col-12">
                            <span class="input-group-addon"><i class="fa fa-envelope ti-email"></i></span>
                            <input type="password" class="form-control" placeholder="Password"
                                formControlName="password"
                                [ngClass]="{ 'is-invalid':formControls.password.errors && isSubmitted}">
                            <div *ngIf="formControls.password" class="invalid-feedback">
                                Enter your password
                            </div>
                        </div>
                        <!-- </div> -->
                        <hr class="hr-xs">
                        <button type="submit" class="btn btn-primary btn-block btnNet"> Login </button>
                        <div class="login-footer"></div>
                    </form>
                    <a routerLink="/reset-password" class="text-left pt-2 ml-1 white-color">Forgot password?</a>

                </div>

            </div>
        </div>
    </div>
</div>