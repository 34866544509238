
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SiteManagerService } from 'src/app/services/site-services/site-manager.service';
import Swal from 'sweetalert2';
import { NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NumberSymbol } from '@angular/common';
import { ngxLoadingAnimationTypes } from 'ngx-loading';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

import { Camelize } from 'src/app/services/helpers/stringCamelCaseValidator.validator';
import { SiteApiService } from 'src/app/services/site-services/site-api';
import { GeneralServiceMessages } from 'src/app/services/messages-services/utilities-message';
import { UtmSourceApiService } from 'src/app/services/utmSources-services/utmSource-api';
import { UtmMediumApiService } from 'src/app/services/utmMedium-services/utmMedium-api';

@Component({
  selector: 'app-site',
  templateUrl: './site.component.html',
  styleUrls: ['./site.component.css']
})
export class SiteComponent implements OnInit {

  public siteCreateForm!: FormGroup;
  public siteUpateForm!: FormGroup;
  public sites: any[] = [];
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;

  private token = '';
  public total = 0;
  public page = 1;
  public rangeRow = 10;
  public step = 0;
  public loading = false;
  public submitted = false;
  public submittedEdit = false;
  public closeModal: string = '';
  public modalRef!: NgbModalRef;
  public sourceData: any;
  public mediumData: any;

  public localFieldsSource: Object = { text: 'utm_source_name', value: 'utm_source_name' };
  public accountMain: any;

  dropdownList: any = [];
  selectedItems: any = [];
  sourceSettings: IDropdownSettings = {};
  mediumSettings: IDropdownSettings = {};

  constructor(
    private formBuilder: FormBuilder,
    private siteManager: SiteManagerService,
    private sourceManager: UtmSourceApiService,
    private mediumManager: UtmMediumApiService,
    private router: Router,
    private modalServices: NgbModal,
    private siteService: SiteApiService,
    private messageSwal: GeneralServiceMessages,
  ) {
  
    this.initializedData()
    this.sourceSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'utm_source_name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
    this.mediumSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'utm_medium_name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

  }

  ngOnInit(): void {
    this.siteCreateForm = this.formBuilder.group({
      nameSiteCtrl: ['', Validators.required],
      sourceCtrl: [''],
      mediumCtrl: [''],

    });

    this.siteUpateForm = this.formBuilder.group({
      nameSiteUpdateCtrl: ['', Validators.required],
      sourceUpdateCtrl:[''],
      mediumUpdateCtrl:[''],
    });

  }
  

  get formUpdate() {
    return this.siteUpateForm.controls;
  }
  get formCreate() {
    return this.siteCreateForm.controls;
  }
  initializedData() {
    this.page = 1;
    this.rangeRow = 10;
    this.step = 0;
    this.sites = [];
    this.total = 0;
    this.loading = true;
    this.siteManager.getSite().then((site: any[]) => {
      this.sites = site;
      this.total = this.sites.length;
    });
    this.sourceManager.getUtmSource().subscribe((sources => {
      this.sourceData = sources.data;
    }));
    this.mediumManager.getUtmMedium().subscribe((medium => {
      this.mediumData = medium.data;
    }));

  }
  openModalCreate(modalDataCreate: any) {
    this.modalRef = this.modalServices.open(modalDataCreate, { ariaLabelledBy: 'modal-dialog modal-dialog-centered' });
  }
  createSite() {
    this.submitted = true;
    if (this.siteCreateForm.invalid) {
      return;
    } else {
      this.loading = true;
      const ids_sources: number[] = [];
      const ids_mediums: number[] = [];
      let name_site = this.siteCreateForm.controls.nameSiteCtrl.value || '';
      const source = this.siteCreateForm.controls.sourceCtrl.value || '';
      const medium = this.siteCreateForm.controls.mediumCtrl.value || '';

      source.map((item: any) => ids_sources.push(item.id));
      medium.map((item: any) => ids_mediums.push(item.id));

      this.siteService.createSite(name_site, ids_sources,ids_mediums).subscribe(response => {
        this.initializedData();
        this.siteCreateForm.reset();
        this.modalRef.close();
        this.messageSwal.opensweetalert('Site successful create');
      }, error => {
        this.loading = false;
        this.validadorResponse(error);
      });
    }
  }

  updateSite(item: any, modalData: any) {
    const idSite = item.id
    this.submittedEdit = true;
    this.siteUpateForm.controls['nameSiteUpdateCtrl'].setValue(item.name_site);
    this.siteUpateForm.controls['sourceUpdateCtrl'].setValue(item.UtmSourceModels);
    this.siteUpateForm.controls['mediumUpdateCtrl'].setValue(item.UtmMediumModels);


    this.modalServices.open(modalData, { ariaLabelledBy: 'modal-basic-title' }).result.then((res) => {
      if (res === 'false') {
        return;
      }
      if (this.siteUpateForm.invalid) {
        Swal.fire({ text: 'Todos los campos yo obligatorios.', timer: 500, showCancelButton: false });
        return;
      } else {
        this.loading = true;
        const ids_sources: number[] = [];
        const ids_mediums: number[] = [];

        const source = this.siteUpateForm.controls.sourceUpdateCtrl.value || '';
        const medium = this.siteUpateForm.controls.mediumUpdateCtrl.value || '';

        let nameSite = this.siteUpateForm.controls.nameSiteUpdateCtrl.value || '';
        source.map((item: any) => ids_sources.push(item.id));
        medium.map((item: any) => ids_mediums.push(item.id));


        this.siteService.updateSite(idSite, nameSite, ids_sources,ids_mediums).subscribe(res => {
          this.initializedData();
          this.messageSwal.opensweetalert('Site successful update');
        }, error => {
          this.loading = false;
          this.validadorResponse(error);
        })
      }
    }, (res) => {
      this.closeModal = `Dismissed ${this.getDismissReason(res)}`;
    });
  }

  deleteSite(id: string) {
    this.messageSwal.openSweetAlertDelete(() => { this.loading = true; }, () => {
      this.siteService.deleteSite(id, '').subscribe(item => {
        this.loading = false;
        this.messageSwal.opensweetalertWithThen(item.message.charAt(0).toUpperCase() + item.message.slice(1),
          () => { this.initializedData(); });
      }, error => {
        this.loading = false;
        this.messageSwal.openSweetAlertError(error.message);
      });
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  validadorResponse(error: any) {
    if (error.status) {
      if (error.status === 403) {
        this.router.navigate(['/login']);
      } else {
        Swal.fire('Oops...', error.message, 'error')
      }
    } else {
      Swal.fire('Oops...', error.message, 'error')
    }
  }

  public openEvent(stepNumb: number) {
    this.step = stepNumb;
  }

  public range(range: NumberSymbol) {
    this.page = 1;
    this.rangeRow = 10;
    this.step = 0;
    this.rangeRow = range;
  }

  public pageChanged(pageIndex: number) {
    this.page = pageIndex;
  }
  validate() {
    this.submittedEdit = true;
    if (this.siteUpateForm.invalid) {
      Swal.fire({ text: 'Fill in the mandatory fields.', timer: 1300, showCancelButton: false });
      return;
    }
  }

}

