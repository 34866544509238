import { Component, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { ClipboardService } from 'ngx-clipboard';
import { map } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { CampaignManagerService } from 'src/app/services/campaign-services/campaign-manager.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CountriesManagerService } from 'src/app/services/countries-services/countries-manager.service';
import { NgbModal, ModalDismissReasons, NgbModalRef, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { QuarterManagerService } from 'src/app/services/quarter-services/quarter-manager.service';
import { FiscalYearManagerService } from 'src/app/services/fiscal-year-services/fiscalYear-manager.service';
import { ObjectiveManager } from 'src/app/services/objective/objective-manager';
import { SiteManagerService } from 'src/app/services/site-services/site-manager.service';
import { CreativeTypeManagerService } from 'src/app/services/creativeType-services/creativeType-manager.service';
import { UtmSourceManagerService } from 'src/app/services/utmSources-services/utmSource-manager.service';
import { UtmMediumManagerService } from 'src/app/services/utmMedium-services/utmMedium-manager.service';
import { UtmContentManagerService } from 'src/app/services/utmContent-services/utmContent-manager.service';
import { CampaignService } from 'src/app/services/campaign-services/campaign.service';
import { GeneralServiceMessages } from 'src/app/services/messages-services/utilities-message';
@Component({
  selector: 'app-campaign-update',
  templateUrl: './campaign-update.component.html',
  styleUrls: ['./campaign-update.component.css']
})
export class CampaignUpdateComponent implements OnInit {
  public campaignUpdateForm!: FormGroup;

  public submitted = false;
  public submittedEdit = false;
  public modelFin: NgbDateStruct | undefined;
  public modelInicio: NgbDateStruct | undefined;
  public state$!: Observable<object>;
  public objtmp: any;

  public loading = false;
  public campaignUpdate: any;
  public countries: any[] = [];
  public quarters: any[] = [];
  public fiscalYears: any[] = [];
  public sites: any[] = [];
  public objectives: any[] = [];
  public creativeTypes: any[] = [];
  public utmSources: any[] = [];
  public utmMediums: any[] = [];
  public utmContents: any[] = [];
  public sourcePlatform: any[] = [];
  public id_ramdom = '';
  public closeModal: string = '';
  modalRef!: NgbModalRef;
  public hiddenUtm = false;
  public isLinear = false;
  public random_id = '';
  public campaign_text: string = '';
  statusCheck!: boolean;

  constructor(private campaignManager: CampaignManagerService,
    private router: Router,
    public activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private countriesManager: CountriesManagerService,
    private modalServices: NgbModal,
    private quarterManager: QuarterManagerService,
    private fiscalYearManager: FiscalYearManagerService,
    private siteManager: SiteManagerService,
    private cretiveTypesManager: CreativeTypeManagerService,
    private objectiveManager: ObjectiveManager,
    private utmSourceManager: UtmSourceManagerService,
    private utmMediumManager: UtmMediumManagerService,
    private utmContentManager: UtmContentManagerService,
    private campaignApiServices: CampaignService,
    private messageSwal: GeneralServiceMessages,
    private clipboardApi: ClipboardService,
  ) {

    this.initializedData();
  
    this.state$ = this.activatedRoute.paramMap.pipe(
      map(() => window.history.state)
    )
    this.state$.subscribe(data => {
      this.objtmp = data;
    });
    if (this.objtmp.campaign) {
      this.toAssignValuesForm();
    } else {
      this.router.navigate(['/dashboard/campaign_administrator']);
    }
  }

  ngOnInit(): void {
    this.campaignUpdateForm = this.formBuilder.group({
      countryNameCtrl: ['', Validators.required],
      siteCtrl: ['', Validators.required],
      objectiveCtrl: ['', Validators.required],
      nameCampaignCtrl: ['', Validators.required],
      quarterCtrl: ['', Validators.required],
      fiscalYearCtrl: ['', Validators.required],
      rotationCtrl: ['50', Validators.required],
      startDateCtrl: ['', Validators.required],
      endDateCtrl: ['', Validators.required],
      urlPageCtrl: ['', Validators.required],
      statusCtrl: [true, Validators.required],
      utmSourceCtrl: ['', Validators.required],
      utmMediumCtrl: ['', Validators.required],
      sourcePlatformCtrl: ['', Validators.required],
      utmContentCtrl: ['', Validators.required],
      creativeTypeCtrl: ['', Validators.required],
      utmCampaignCtrl: ['', Validators.required],
      utmIdCtrl: ['', Validators.required],
      utmGeneratedCtrl: ['', Validators.required],
      bugetCtrl: ['', Validators.required],
    });

  }


  async updateCampaign() {
    this.submitted = true;
    if (this.campaignUpdateForm.invalid) {
      Swal.fire({ text: 'Todos los campos yo obligatorios.', timer: 500, showCancelButton: false });
      return;
    } else {
      this.loading = true;
      const countryCode = this.campaignUpdateForm.controls['countryNameCtrl'].value;
      const campaign_name = this.campaignUpdateForm.controls['nameCampaignCtrl'].value;
      const site = this.campaignUpdateForm.controls['siteCtrl'].value;
      const objective = this.campaignUpdateForm.controls['objectiveCtrl'].value;
      const quarter = this.campaignUpdateForm.controls['quarterCtrl'].value;
      const fiscalYear = this.campaignUpdateForm.controls['fiscalYearCtrl'].value;
      const creative_type = this.campaignUpdateForm.controls['creativeTypeCtrl'].value;
      const start_date: string = this.campaignUpdateForm.controls['startDateCtrl'].value;
      const end_date: string = this.campaignUpdateForm.controls['endDateCtrl'].value;
      const linking_url = this.campaignUpdateForm.controls['urlPageCtrl'].value;
      const rotation = this.campaignUpdateForm.controls['rotationCtrl'].value;
      const status = this.campaignUpdateForm.controls['statusCtrl'].value;
      const utm_source = this.campaignUpdateForm.controls['utmSourceCtrl'].value;
      const utm_medium = this.campaignUpdateForm.controls['utmMediumCtrl'].value;
      const source_platform = this.campaignUpdateForm.controls['sourcePlatformCtrl'].value;
      const id_ramdom = this.campaignUpdate.random_id;
      const utm_campaign = this.campaignUpdateForm.controls['utmCampaignCtrl'].value;
      const utm_id = this.campaignUpdateForm.controls['utmIdCtrl'].value;;
      const utm_content = this.campaignUpdateForm.controls['utmContentCtrl'].value;
      const utm_generated = this.campaignUpdateForm.controls['utmGeneratedCtrl'].value;
      const buget = this.campaignUpdateForm.controls['bugetCtrl'].value;

      const info = {
        id: this.campaignUpdate.id,
        market_id: countryCode,
        campaing_name: campaign_name,
        site_id: site,
        objective_id: objective,
        quarter_id: quarter,
        fiscal_year_id: fiscalYear,
        creative_type_id: creative_type,
        creative_start_date: start_date,
        creative_end_date: end_date,
        linking_url: linking_url,
        rotation: rotation,
        status: status,
        utm_source_id: utm_source,
        utm_medium_id: utm_medium,
        utm_source_platform: source_platform,
        utm_campaign: utm_campaign,
        random_id: id_ramdom,
        utm_content_id: utm_content,
        utm_generated: utm_generated,
        campaign_created: this.campaignUpdate.campaign_created,
        utm_id: utm_id,
        buget: buget
      }
      this.campaignApiServices.updateCampaign(info.id, info).subscribe(response => {
        this.loading = false;
        this.messageSwal.opensweetalert('Campaign successful update');
        this.router.navigateByUrl('/dashboard/campaign_administrator')

      }, error => {
        this.loading = false;
        this.validadorResponse(error);
      })
    }

  }

  async toAssignValuesForm() {
    const campaignId = this.objtmp.campaign.id;
    this.submittedEdit = true;
    await this.campaignManager.getCampaignById(campaignId).then((campaign: any) => {
      this.campaignUpdate = campaign;
    });
   
    this.campaignUpdateForm.controls['countryNameCtrl'].setValue(this.campaignUpdate.market_id);
    this.campaignUpdateForm.controls['objectiveCtrl'].setValue(this.campaignUpdate.objective_id);
    this.campaignUpdateForm.controls['nameCampaignCtrl'].setValue(this.campaignUpdate.campaing_name);
    this.campaignUpdateForm.controls['quarterCtrl'].setValue(this.campaignUpdate.quarter_id);
    this.campaignUpdateForm.controls['siteCtrl'].setValue(this.campaignUpdate.site_id);
    this.campaignUpdateForm.controls['fiscalYearCtrl'].setValue(this.campaignUpdate.fiscal_year_id);
    this.campaignUpdateForm.controls['creativeTypeCtrl'].setValue(this.campaignUpdate.creative_type_id);
    this.campaignUpdateForm.controls['rotationCtrl'].setValue(this.campaignUpdate.rotation);
    const sd = new Date(this.campaignUpdate.creative_start_date);
    this.modelInicio = { year: sd.getFullYear(), month: sd.getMonth() + 1, day: sd.getDate() };
    const ed = new Date(this.campaignUpdate.creative_end_date);
    this.modelFin = { year: ed.getFullYear(), month: ed.getMonth() + 1, day: ed.getDate() };
    this.campaignUpdateForm.controls['urlPageCtrl'].setValue(this.campaignUpdate.linking_url);
    this.campaignUpdateForm.controls['statusCtrl'].setValue(this.campaignUpdate.status)
    this.campaignUpdateForm.controls['utmSourceCtrl'].setValue(this.campaignUpdate.utm_source_id);
    this.campaignUpdateForm.controls['utmMediumCtrl'].setValue(this.campaignUpdate.utm_medium_id);
    this.campaignUpdateForm.controls['sourcePlatformCtrl'].setValue(this.campaignUpdate.utm_source_platform);
    this.campaignUpdateForm.controls['utmContentCtrl'].setValue(this.campaignUpdate.utm_content_id);
    this.campaignUpdateForm.controls['utmCampaignCtrl'].setValue(this.campaignUpdate.utm_campaign);
    this.campaignUpdateForm.controls['utmIdCtrl'].setValue(this.campaignUpdate.utm_id);
    this.campaignUpdateForm.controls['bugetCtrl'].setValue(this.campaignUpdate.buget);

    this.random_id = this.campaignUpdate.random_id;

    this.loadSourcesAndMediums(null)
    // const channel_id = this.campaignUpdateForm.controls.siteCtrl.value;
    // console.log(channel_id);
    // const select_channel_sources = this.sites?.find((item: any) => parseInt(item.id) === parseInt(channel_id));
    // this.utmSources = select_channel_sources.UtmSourceModels
  }

  initializedData() {

    this.countriesManager.getCountries().then((country: any[]) => {
      this.countries = country;
    });
    this.quarterManager.getQuarter().then((quarter: any[]) => {
      this.quarters = quarter;
    });
    this.fiscalYearManager.getFiscalYear().then((fiscalYear: any[]) => {
      this.fiscalYears = fiscalYear;
    });
    this.siteManager.getSite().then((site: any[]) => {
      this.sites = site;
    });
    this.objectiveManager.getObjective().then((objective: any[]) => {
      this.objectives = objective;
    });
    this.cretiveTypesManager.getCreativeType().then((creativeType: any[]) => {
      this.creativeTypes = creativeType;
    });

    this.utmSourceManager.getUtmSource().then((sources: any[]) => {
      this.sourcePlatform = sources;
    });
    this.utmContentManager.getUtmContent().then((utm_content: any[]) => {
      this.utmContents = utm_content;
    });
  }
  get formUpdate() {
    return this.campaignUpdateForm.controls;
  }

  checkboxChange() {
    if (this.statusCheck) {
      this.statusCheck = false;
    } else {
      this.statusCheck = true;
    }
  }
  loadSourcesAndMediums(event: any) {
    const channel_id = this.campaignUpdateForm.controls.siteCtrl.value;
    const select_channel_sources = this.sites?.find((item: any) => parseInt(item.id) === parseInt(channel_id));
    this.utmSources = select_channel_sources.UtmSourceModels

    const select_channel_medium = this.sites?.find((item: any) => parseInt(item.id) === parseInt(channel_id));
    this.utmMediums = select_channel_medium.UtmMediumModels
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  generateUtmID(event?: Event) {
    const utmMedium = this.utmMediums?.find(resp => parseInt(resp.id) === parseInt(this.campaignUpdateForm.controls.utmMediumCtrl.value));
    const utm_id = `${utmMedium?.utm_medium_name}_${this.random_id}`;
    this.campaignUpdateForm.controls['utmIdCtrl'].setValue(utm_id);
    return utm_id;
  }

  generateUtmCampaign(event: Event) {
    const utmSourceValue = this.campaignUpdateForm.controls.utmSourceCtrl.value;
    const countryValue = this.campaignUpdateForm.controls.countryNameCtrl.value;
    if (utmSourceValue !== '' && countryValue !== '') {
      const countryCode = this.countries?.find(resp => parseInt(resp.id) === parseInt(countryValue));
      const utmSource = this.utmSources?.find(resp => parseInt(resp.id) === parseInt(utmSourceValue));
      const utm_campaign = `${countryCode.country_code}_${utmSource?.utm_source_name}`;
      return utm_campaign;
    }
    return null;
  }

  generateUtm() {
    const linking_URL = this.campaignUpdateForm.controls.urlPageCtrl.value;
    const utm_source = this.utmSources?.find(resp => parseInt(resp.id) === parseInt(this.campaignUpdateForm.controls.utmSourceCtrl.value));
    const utm_source_platform = this.sourcePlatform?.find(resp => parseInt(resp.id) === parseInt(this.campaignUpdateForm.controls.sourcePlatformCtrl.value));
    const utm_content = this.utmContents?.find(resp => parseInt(resp.id) === parseInt(this.campaignUpdateForm.controls.utmContentCtrl.value));
    const utm_medium = this.utmMediums?.find(resp => parseInt(resp.id) === parseInt(this.campaignUpdateForm.controls.utmMediumCtrl.value));
    const utm_campaign = this.campaignUpdateForm.controls.utmCampaignCtrl.value;
    const utm_id = this.campaignUpdateForm.controls.utmIdCtrl.value;
    const utm_generated = `${linking_URL}&utm_source=${utm_source?.utm_source_name}&utm_medium=${utm_medium?.utm_medium_name}&utm_campaign=${utm_campaign}&utm_id=${utm_id}&utm_source_platform=${utm_source_platform.utm_source_name}&utm_content=${utm_content.utm_content_name}`;
    this.hiddenUtm = true;
    this.campaignUpdateForm.controls['utmGeneratedCtrl'].setValue(utm_generated.toLowerCase());
  }
  validadorResponse(error: any) {
    if (error.status) {
      if (error.status === 403) {
        this.router.navigate(['/login']);
      } else {
        Swal.fire('Oops...', error.message, 'error')
      }
    } else {
      Swal.fire('Oops...', error.message, 'error')
    }
  }

  copyToClipboard() {
    this.campaign_text = this.campaignUpdateForm.controls.utmGeneratedCtrl.value;
    this.clipboardApi.copyFromContent(this.campaign_text || '');
    Swal.fire({ text: 'The Campaign was copied to the clipboard.', timer: 500, showCancelButton: false });
  }


}
