import { NumberSymbol } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthSerices } from 'src/app/services/auth-services/auth-api.service';
import { GeneralServiceMessages } from 'src/app/services/messages-services/utilities-message';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {
  public users: any[] = [];
  private token = '';
  public total = 0;
  public page = 1;
  public rangeRow = 10;
  public step = 0;
  public loading = false;
  public submitted = false;
  public submittedEdit = false;

  constructor(    
    private messageSwal: GeneralServiceMessages,
    private userService: AuthSerices,
    private router: Router,


    ) {
      this.initializedData();
     }

  ngOnInit(): void {  
  }
  deleteUser(id: string) {
    this.messageSwal.openSweetAlertDelete(() => { this.loading = true; }, () => {
      this.userService.deleteUser(id).subscribe(item => {
        this.loading = false;
        this.messageSwal.opensweetalertWithThen(item.message.charAt(0).toUpperCase() + item.message.slice(1),
          () => { this.initializedData(); });
      }, error => {
        this.loading = false;
        this.messageSwal.openSweetAlertError(error.message);
      });
    });
  }
  public range(range: NumberSymbol) {
    this.page = 1;
    this.rangeRow = 10;
    this.step = 0;
    this.rangeRow = range;
  }
  public pageChanged(pageIndex: number) {
    this.page = pageIndex;
  }
  public addUser() {
    this.router.navigateByUrl('/dashboard/user-add');
  }
  public updateUser(user: any) {
    this.router.navigateByUrl('/dashboard/user-edit', { state: { user } });
  }
  initializedData(){
    this.page = 1;
    this.rangeRow = 10;
    this.step = 0;
    this.users = [];
    this.total = 0;
    this.loading = true;
  this.userService.getUser().subscribe(response => {
      this.users = response.data;
      this.total = this.users.length;
    });
  }
}
