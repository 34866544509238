import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AgencyApiService {

  constructor(private http: HttpClient) {
  }
  getAgency(token: string): Observable<any> {
    const headers = { 'content-type': 'application/json', 'Access-Control-Allow-Origin': '*', 'Access-Control-Allow-Methods': 'POST, OPTIONS', 'Authorization': `Bearer ${token}` }
    return this.http.get(environment.urlservices + '/agency', { 'headers': headers })
  }

  getOneAgency(token: string): Observable<any> {
    const headers = { 'content-type': 'application/json', 'Access-Control-Allow-Origin': '*', 'Access-Control-Allow-Methods': 'POST, OPTIONS', 'Authorization': `Bearer ${token}` }
    return this.http.get(environment.urlservices + '/agency_one', { 'headers': headers })
  }

  createAgency(nameAgency: string, token: string): Observable<any> {
    const body = JSON.stringify({
      name_agency: nameAgency
    });
    const headers = { 'content-type': 'application/json', 'Access-Control-Allow-Origin': '*', 'Access-Control-Allow-Methods': 'POST, OPTIONS', 'Authorization': `Bearer ${token}` }
    return this.http.post(environment.urlservices + '/agency_new', body, { 'headers': headers })
  }

  deleteAgency(nameAgency: string, token: string): Observable<any> {
    const body = JSON.stringify({
      name_agency: nameAgency
    });
    const headers = { 'content-type': 'application/json', 'Access-Control-Allow-Origin': '*', 'Access-Control-Allow-Methods': 'POST, OPTIONS', 'Authorization': `Bearer ${token}` }
    return this.http.post(environment.urlservices + '/agency_delete', body, { 'headers': headers })
  }

  editAgency(nameAgency: string, agencyId: string, token: string): Observable<any> {
    const body = JSON.stringify({
      agencyName: nameAgency,
      agency: agencyId
    });
    const headers = { 'content-type': 'application/json', 'Access-Control-Allow-Origin': '*', 'Access-Control-Allow-Methods': 'POST, OPTIONS', 'Authorization': `Bearer ${token}` }
    return this.http.post(environment.urlservices + '/agency_edit', body, { 'headers': headers })
  }
}