import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import jwt_decode from 'jwt-decode';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  public usuario: boolean = false;
  public user: any;

  public campaignMenu: boolean = false;
  public placementMenu: boolean = false;
  public creativeMenu: boolean = false;
  public flooligthMenu: boolean = false;
  public administradorMenu: boolean = false;
  public managementMenu: boolean = false;
  public mediaPlanMenu: boolean = false;
  public managmentCampaign: boolean = false;
  public managmentMediaPlan: boolean = false;
  public managmentPlacement: boolean = false;
  public optionsMenu: boolean = false;
  isCollapsed = true;


  public menuRole = {
    "administrator": {
      campaignMenu: true,
      mediaPlanMenu: true,
      placementMenu: true,
      creativeMenu: true,
      flooligthMenu: true,
      administradorMenu: true,
      managementMenu: true,
      managmentCampaign: true,
      managmentMediaPlan: true,
      managmentPlacement: true,
      optionsMenu: true
    },
    "operation": {
      campaignMenu: true,
      mediaPlanMenu: true,
      placementMenu: false,
      creativeMenu: false,
      flooligthMenu: false,
      administradorMenu: true,
      managementMenu: false,
      managmentCampaign: true,
      managmentMediaPlan: true,
      managmentPlacement: false,
      optionsMenu: false
    },
    "generator": {
      campaignMenu: false,
      mediaPlanMenu: false,
      placementMenu: true,
      creativeMenu: false,
      flooligthMenu: false,
      administradorMenu: true,
      managementMenu: false,
      managmentCampaign: false,
      managmentMediaPlan: false,
      managmentPlacement: true,
      optionsMenu: false
    }
    ,
    "media_plan": {
      campaignMenu: true,
      placementMenu: true,
      creativeMenu: false,
      mediaPlanMenu: true,
      flooligthMenu: false,
      administradorMenu: false,
      managementMenu: false,
      managmentCampaign: true,
      managmentMediaPlan: true,
      managmentPlacement: true,
      optionsMenu: false
    }
  }

  constructor(private router: Router, private storageService: StorageService,) {
    const response = this.storageService.get('profiles');
    if (response) {
      const decoded: any = jwt_decode(response);
      this.user = decoded.user;
      if (decoded.perfil === 'administrator') {
        this.rolMenuPermise(this.menuRole.administrator)
      } else if (decoded.perfil === 'generator') {
        this.rolMenuPermise(this.menuRole.generator)
      } else if (decoded.perfil === 'operation') {
        this.rolMenuPermise(this.menuRole.operation)
      } else if (decoded.perfil === 'media_plan') {
        this.rolMenuPermise(this.menuRole.media_plan)
      } else {
        this.router.navigate(['/login']);
      }
    } else {
      this.router.navigate(['/login']);
    }
  }

  private rolMenuPermise(info: any) {
    this.campaignMenu = info.campaignMenu;
    this.placementMenu = info.placementMenu;
    this.creativeMenu = info.creativeMenu;
    this.mediaPlanMenu = info.mediaPlanMenu;
    this.flooligthMenu = info.flooligthMenu;
    this.administradorMenu = info.administradorMenu;
    this.managementMenu = info.managementMenu;
    this.managmentCampaign = info.managmentCampaign;
    this.managmentMediaPlan = info.managmentMediaPlan;
    this.managmentPlacement = info.managmentPlacement;
    this.optionsMenu = info.optionsMenu;
  }

  ngOnInit(): void {

  }

  isNavbarCollapsed = true;

  public navigationAction(action: string) {
    this.router.navigate([action]);
  }

  public cerrarSesion() {
    console.log(this.storageService.get('profiles'));
    this.storageService.remove('profiles');
    this.router.navigate(['/login']);
  }
  cerrarPanel(): void {
    this.isCollapsed = false;
  }
}
