import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CreativeTypeApiService {

  constructor(private http: HttpClient) {
  }
  getCreativeType(token: string): Observable<any> {
    return this.http.get(environment.urlservices + '/creative-types',)
  }

  createCreativeType(name_creative_type: string, token: string): Observable<any> {
    const body = {
      name_creative_type
    };
    
    return this.http.post(environment.urlservices + '/creative-types', body,)
  }

  deleteCreativeType(id: string, token: string): Observable<any> {
    
    return this.http.delete(`${environment.urlservices}/creative-types/${id}`,)
  }

  updateCreativeType(id: string, name: string, token: string): Observable<any> {
    const body = {
      creative_type_id: id,
      name_creative_type: name
    };
    return this.http.put(`${environment.urlservices}/creative-types/${id}`, body,)
  }
}