import { Injectable } from '@angular/core';
import { CountriesApiService } from './countries-api';
import { Router } from '@angular/router';
import { GeneralServiceMessages } from '../messages-services/utilities-message';

@Injectable({
  providedIn: 'root'
})
export class CountriesManagerService {
  public countries: any[] = [];
  public loading = false;
  public token = '';

  constructor(
    private countriesServices: CountriesApiService,
    private router: Router,
    private messageSweet: GeneralServiceMessages
  ) {
    this.token = sessionStorage.getItem('data') || '';
  }

  getCountries(): Promise<any[]> {
    this.loading = true;
    return new Promise<any[]>((resolve, reject) => {
      this.countriesServices.getCountryCode(this.token).subscribe(
        response => {
          this.countries = response.data;
          resolve(this.countries);
        },
        error => {
          this.countries = [];
          this.loading = false;
          reject(this.countries);
        }
      );
    });
  }

}