import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { AuthApiService } from './session-control.service';
import { StorageService } from './storage.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private hdrs: AuthApiService, private storage: StorageService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const excludedUrls = ['/users/login','/users/sendEmail','/users/reset-password','/users/statusLink','/users/verify-email'];
    const isExcludedUrl = excludedUrls.some(url => req.url.includes(url));
    if (!isExcludedUrl) {
      const token = this.tokenAuth();
      if (token) {
        req = req.clone({ headers: token });
      }
    }
    return next.handle(req).pipe(
      tap(
        event => {
        },
        error => {
          console.log(error);
          if (error.status === 401 || error.mesaje === 'Token is invalid') {
            Swal.fire({
              icon: 'warning',
              text: 'Session expired',
            }).then(() => {
              this.hdrs.logout();
            });
            return;
          }

        }
      )
    );
  }

  tokenAuth() {
    const dataprofile = JSON.parse(this.storage.get('profiles'));
    const headers = new HttpHeaders()
      .set('Authorization', `Bearer ${dataprofile.token}`);
    return headers;
  }
}