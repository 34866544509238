<ngx-loading [show]="loading" [config]="{fullScreenBackdrop: true}"></ngx-loading>
<div class="container">
    <div class="row">
        <div class="col-12">
            <br>
            <div class="container">
                <div class="row">
                    <div class="col-6">
                        <h2 class="h5">Fiscal Years</h2>
                    </div>
                    <div class="col-6 text-end">
                        <button type="submit" class="btn btn-primary  btnNet" (click)="openModalCreate(modalDataCreate)">
                            <span><i class="bi bi-plus-circle-fill bi-size-3x"> Add fiscal year</i></span>
                        </button>
                    </div>
                </div>
            </div>
            <label for="inputExchange">&nbsp;</label><br>
        </div>
        <div class="col-12">
            <div class="">
                <div class="item-wrapper">
                    <div class="table">
                        <table class="table table-striped table-hover">
                            <thead>
                                <tr>
                                    <th>Id fiscalYear</th>
                                    <th>Name fiscal year</th>
                                    <th colspan="2">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    *ngFor="let item of fiscalYears | paginate: { itemsPerPage: rangeRow, currentPage: page , totalItems: total}; let i = index">
                                    <td>{{item.id}}</td>
                                    <td>{{item.name_fiscal_year}}</td>
                                    <td>
                                        <a href="javascript: void(0);" (click)="updateFiscalYear(item, modalData)"><i class="bi bi-pencil-square text-dark"></i></a>
                                    </td>
                                    <td>
                                        <a href="javascript: void(0);" (click)="deleteFiscalYear(item.id)"><i class="bi bi-trash-fill text-dark"></i></a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="dataEmpty" *ngIf="fiscalYears.length < 1">
                            <p>No records found</p>
                        </div>
                        <div *ngIf="fiscalYears.length > 0" class="table-control">
                            <div class="container">
                                <div class="row">
                                    <div class="col-12 text-center">
                                        <pagination-controls (pageChange)="pageChanged(page=$event)" previousLabel=""
                                            nextLabel="">
                                        </pagination-controls>
                                    </div>
                                    <div class="col-12 text-end">
                                        <select class="mdb-select colorful-select dropdown-primary mt-2 hidden-md-down"
                                            [ngModel]="rangeRow" (ngModelChange)="range($event)">
                                            <option value="" disabled>Number of rows</option>
                                            <option value="10" selected>10</option>
                                            <option value="25">25</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #modalData let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Edit fiscal year</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="fiscalYearUpateForm">
            <div class="form-row">
                <div class="col-xs-12 col-sm-12 col-md-12">
                    <label for="inputExchange">Name fiscal year</label>
                    <input type="text" class="form-control" placeholder="Name fiscal year"
                        formControlName="nameFiscalYearUpdateCtrl"
                        [ngClass]="{ 'is-invalid':formUpdate.nameFiscalYearUpdateCtrl.errors && submittedEdit}">
                    <div *ngIf="formUpdate.nameFiscalYearUpdateCtrl.errors?.required" class="invalid-feedback">
                        Name fiscal year is required
                    </div>
                </div>

            </div>
        </form>
    </div>
    <div class="modal-footer text-center">
        <button type="button" class="btn btn-primary "
            (click)="!fiscalYearUpateForm.invalid ? modal.close() : validate()">Update fiscalYear</button>
        <button type="button" class="btn btn-secondary" (click)="modal.close('false')">Cancel</button>
    </div>
</ng-template>

<ng-template #modalDataCreate let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Create fiscal year</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="fiscalYearCreateForm">
            <div class="form-row">
                <div class="col-xs-12 col-sm-12 col-md-12">
                    <label for="inputExchange">Name fiscal year</label>
                    <input type="text" class="form-control" placeholder="Name fiscal year"
                        formControlName="nameFiscalYearCtrl"
                        [ngClass]="{ 'is-invalid':formCreate.nameFiscalYearCtrl.errors && submitted}">
                    <div *ngIf="formCreate.nameFiscalYearCtrl.errors" class="invalid-feedback">
                        Name fiscal year is required.
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer text-center">
        <button type="submit" class="btn btn-primary "(click)="createFiscalYear()">Create fiscal year</button>
        <button type="button" class="btn btn-secondary" (click)="modal.close('false')">Cancel</button>
    </div>
</ng-template>