import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { FiscalYearApiService } from './fiscalYear-api';
import { Router } from '@angular/router';
import { GeneralServiceMessages } from '../messages-services/utilities-message';

@Injectable({
    providedIn: 'root'
})
export class FiscalYearManagerService {
    public loading = false;
    public token = '';

    constructor(
        private fiscalYearService: FiscalYearApiService,
        private router: Router,
        private messageSweet: GeneralServiceMessages
    ) {
        this.token = sessionStorage.getItem('data') || '';
    }
    getFiscalYear() {
        return new Promise<any[]>((resolve, reject) => {
            this.loading = true;
            this.fiscalYearService.getFiscalYear().subscribe(response => {
                this.loading = false;
                resolve(response.data);
            },
                error => {
                    this.loading = false;
                    reject([]);
                }
            );
        });

    }
}