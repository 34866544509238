<div class="container">
    <mat-horizontal-stepper [linear]="isLinear" #stepper>
        <mat-step [stepControl]="campaignUpdateForm">
            <ng-template matStepLabel>Step 1</ng-template>
            <form [formGroup]="campaignUpdateForm">
                <div class="form-row">

                    <div class="col-xs-12 col-sm-6 col-md-6 mt-2 ">
                        <div class="form-floating">
                            <select class="form-select" formControlName="countryNameCtrl"
                                [ngClass]="{ 'is-invalid':formUpdate.countryNameCtrl.errors && submitted}"
                                (ngModelChange)="generateUtmCampaign($event)">
                                <option value="" selected>Select market</option>
                                <option *ngFor="let country of countries" value="{{country.id}}">
                                    {{country.name_country}}</option>
                            </select>
                            <label for="inputTipoCanal">Market</label>
                            <div *ngIf="formUpdate.countryNameCtrl.errors?.required" class="invalid-feedback">
                                Market is required.
                            </div>
                        </div>
                        <p class="text-muted"><small>Where will you place the link?</small></p>
                    </div>

                    <div class="col-xs-12 col-sm-6 col-md-6 mt-2 ">
                        <div class="form-floating">

                            <select class="form-select" formControlName="objectiveCtrl"
                                [ngClass]="{ 'is-invalid':formUpdate.objectiveCtrl.errors && submitted}">
                                <option value="" selected>Select objective</option>
                                <option *ngFor="let objective of objectives" value="{{objective.id}}">
                                    {{objective.name_objective}}
                                </option>
                            </select>
                            <label for="inputTipoCanal">Objective</label>
                            <div *ngIf="formUpdate.objectiveCtrl.errors?.required" class="invalid-feedback">
                                Objective is required.
                            </div>
                        </div>
                        <p class="text-muted"><small>Audience/ Tactic/ Creative concept</small></p>
                    </div>

                    <div class="col-xs-12 col-sm-6 col-md-6 mt-2 ">
                        <div class="form-floating">
                            <input type="text" class="form-control" id="floatingInput" placeholder="Campaign name"
                                formControlName="nameCampaignCtrl"
                                [ngClass]="{ 'is-invalid':formUpdate.nameCampaignCtrl.errors && submitted}">
                            <label for="floatingInput">Campaign name</label>
                        </div>
                        <div *ngIf="formUpdate.nameCampaignCtrl.errors?.required" class="invalid-feedback">
                            Campaign name is required.
                        </div>
                        <p class="text-muted"><small>Campaign Name (separate words with "_" )</small></p>
                    </div>
                    <div class="col-xs-12 col-sm-6 col-md-6 mt-2 ">
                        <div class="form-floating">
                            <select class="form-select" formControlName="quarterCtrl"
                                [ngClass]="{ 'is-invalid':formUpdate.quarterCtrl.errors && submitted}">
                                <option value="" selected>Select quarter</option>
                                <option *ngFor="let quarter of quarters" value="{{quarter.id}}">{{quarter.name_quarter}}
                                </option>
                            </select>
                            <label for="inputTipoCanal">Quarter</label>
                            <div *ngIf="formUpdate.quarterCtrl.errors?.required" class="invalid-feedback">
                                Quarter is required.
                            </div>
                        </div>
                        <p class="text-muted"><small>The marketing quarter, a three (3) month period</small></p>
                    </div>
                    <div class="col-xs-12 col-sm-6 col-md-6 mt-2 ">
                        <div class="form-floating">
                            <select class="form-select" formControlName="siteCtrl"
                                [ngClass]="{ 'is-invalid':formUpdate.siteCtrl.errors && submitted}"
                                (change)="loadSourcesAndMediums($event)">
                                <option value="" selected>Select channel</option>
                                <option *ngFor="let site of sites" value="{{site.id}}">{{site.name_site}}</option>
                            </select>
                            <label for="inputTipoCanal">Channel</label>
                            <div *ngIf="formUpdate.siteCtrl.errors?.required" class="invalid-feedback">
                                Channel is required.
                            </div>
                        </div>
                        <p class="text-muted"><small>The specific medium or platform used.</small></p>

                    </div>
                    <div class="col-xs-12 col-sm-6 col-md-6 mt-2 ">
                        <div class="form-floating">
                            <select class="form-select" formControlName="fiscalYearCtrl"
                                [ngClass]="{ 'is-invalid':formUpdate.fiscalYearCtrl.errors && submitted}">
                                <option value="" selected>Select fiscal year</option>
                                <option *ngFor="let fiscalYear of fiscalYears" value="{{fiscalYear.id}}">
                                    {{fiscalYear.name_fiscal_year}}
                                </option>
                            </select>
                            <label for="inputTipoCanal">Fiscal year</label>
                            <div *ngIf="formUpdate.fiscalYearCtrl.errors?.required" class="invalid-feedback">
                                Fiscal year is required.
                            </div>
                        </div>
                        <p class="text-muted"><small>Planned program of activities</small></p>
                    </div>

                    <div class="col-xs-12 col-sm-6 col-md-6 mt-2">
                        <div class="input-group mb-3">
                            <span class="input-group-text">$</span>
                            <input type="text" class="form-control" id="floatingInput"
                                aria-label="Amount (to the nearest dollar)" placeholder="Planned Spend"
                                formControlName="bugetCtrl"
                                [ngClass]="{ 'is-invalid':formUpdate.bugetCtrl.errors && submitted}">

                            <span class="input-group-text">.00</span>

                            <div *ngIf="formUpdate.bugetCtrl.errors?.required" class="invalid-feedback">
                                Planned Spend is required.
                            </div>
                        </div>
                        <p class="text-muted"><small>Planned spend (Suggested expense)</small></p>
                    </div>
                </div>
                <div class="mx-auto p-2 " style="width: 90px;">
                    <button mat-button matStepperNext class="btn btn-dark ">Next</button>
                </div>
            </form>
        </mat-step>

        <mat-step [stepControl]="campaignUpdateForm" label="Step 2">
            <form [formGroup]="campaignUpdateForm">
                <div class="form-row">

                    <div class="col-xs-12 col-sm-6 col-md-6 mt-2 ">
                        <div class="form-floating">
                            <select class="form-select" formControlName="creativeTypeCtrl"
                                [ngClass]="{ 'is-invalid':formUpdate.creativeTypeCtrl.errors && submitted}">
                                <option value="" selected>Select Creative type</option>
                                <option *ngFor="let creativeType of creativeTypes" value="{{creativeType.id}}">
                                    {{creativeType.name_creative_type}}
                                </option>
                            </select>
                            <label for="inputTipoCanal">Creative type</label>
                            <div *ngIf="formUpdate.creativeTypeCtrl.errors?.required" class="invalid-feedback">
                                Creative type is required.
                            </div>
                        </div>
                    </div>

                    <div class="col-6">
                        <div class="col-xs-12 col-sm-12 col-md-12 mt-2 border rounded">
                            <label for="customRange1" class="form-label">Rotation</label>
                            <span> {{ formUpdate.rotationCtrl.value }}%</span>
                            <input type="range" class="form-range" formControlName="rotationCtrl" min="0" max="100"
                                [value]="formUpdate.rotationCtrl.value">
                        </div>
                        <p class="text-muted"><small>Rotation %</small></p>
                    </div>

                    <div class="col-xs-12 col-sm-6 col-md-6  mt-2">
                        <div class="input-group">
                            <input class="form-control" placeholder="Creative Start Date" name="dpInicio"
                                [(ngModel)]="modelInicio" ngbDatepicker #d="ngbDatepicker"
                                formControlName="startDateCtrl"
                                [ngClass]="{ 'is-invalid':formUpdate.startDateCtrl.errors && submitted}"
                                [readonly]="true" container="body">
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary calendar" (click)="d.toggle()"
                                    type="button"></button>
                            </div>
                            <div *ngIf="formUpdate.startDateCtrl.errors?.required" class="invalid-feedback">
                                Begin Date is required.
                            </div>
                        </div>
                        <p class="text-muted"><small>Creative Start Date</small></p>

                    </div>
                    <div class="col-xs-12 col-sm-6 col-md-6 mt-2">
                        <div class="input-group">
                            <input class="form-control" placeholder="Creative End Date" name="dpFin"
                                [(ngModel)]="modelFin" ngbDatepicker #d1="ngbDatepicker" formControlName="endDateCtrl"
                                [readonly]="true" [ngClass]="{ 'is-invalid':formUpdate.endDateCtrl.errors && submitted}"
                                container="body">
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary calendar" (click)="d1.toggle()"
                                    type="button"></button>
                            </div>
                        </div>
                        <p class="text-muted"><small>Creative End Date</small></p>
                    </div>

                    <div class="col-xs-12 col-sm-6 col-md-6 mt-2 ">
                        <div class="form-floating">
                            <input type="text" class="form-control" id="floatingInput" placeholder="Campaign name"
                                formControlName="urlPageCtrl"
                                [ngClass]="{ 'is-invalid':formUpdate.urlPageCtrl.errors && submitted}">
                            <label for="floatingInput">Linking URL</label>
                        </div>
                        <div *ngIf="formUpdate.urlPageCtrl?.errors?.required" class="invalid-feedback">
                            Linking URL is required.
                        </div>
                        <p class="text-muted"><small>URL Landing Page</small></p>

                    </div>

                    <div class="col-xs-12 col-sm-6 col-md-6 mt-2">
                        <div class="form-check">
                            <input class="form-check-input custom-checkbox" type="checkbox" id="statusCheck"
                                [(ngModel)]="statusCheck" (change)="checkboxChange()" formControlName="statusCtrl" checked>
                            <label class="form-check-label custom-checkbox" for="statusCheck">
                                {{ statusCheck ? 'Active' : 'Inactive'}}
                            </label>
                        </div>
                    </div>
                </div>
                <br>
                <div class="col-xs-12 col-sm-12 col-md-12 mt-2">
                    <div class="row">
                        <div class="col-6 p-2 text-end " style="width: 90px;">
                            <button mat-button matStepperPrevious class="btn btn-dark ">Back</button>
                        </div>
                        <div class="col-6 p-2 text-start " style="width: 90px;">
                            <button mat-button matStepperNext class="btn btn-dark ">Next</button>
                        </div>
                    </div>
                </div>
            </form>
        </mat-step>
        <!-- STEP 3 -->
        <mat-step [stepControl]="campaignUpdateForm">
            <form [formGroup]="campaignUpdateForm">
                <ng-template matStepLabel>Step 3</ng-template>
                <div class="form-row">

                    <div class="col-xs-12 col-sm-6 col-md-6 mt-2 ">
                        <div class="form-floating">
                            <select class="form-select" formControlName="utmSourceCtrl"
                                [ngClass]="{ 'is-invalid':formUpdate.utmSourceCtrl.errors && submitted}"
                                (ngModelChange)="generateUtmCampaign($event)">
                                <option value="" selected>Select UTM Source</option>
                                <option *ngFor="let utmSource of utmSources" value="{{utmSource.id}}">
                                    {{utmSource.utm_source_name}}
                                </option>
                            </select>
                            <label for="inputTipoCanal">UTM source</label>
                            <div *ngIf="formUpdate.utmSourceCtrl.errors?.required" class="invalid-feedback">
                                UTM source is required.
                            </div>
                        </div>
                        <p class="text-muted"><small>Where will you place the link?</small></p>
                    </div>

                    <div class="col-xs-12 col-sm-6 col-md-6 mt-2 ">
                        <fieldset disabled>
                            <div class="form-floating">
                                <input type="text" class="form-control" id="floatingInput" placeholder="UTM campaign"
                                    formControlName="utmCampaignCtrl"
                                    [ngClass]="{ 'is-invalid':formUpdate.utmCampaignCtrl.errors && submitted}">
                                <label for="floatingInput">UTM campaign</label>
                            </div>

                        </fieldset>
                        <p class="text-muted"><small>Product, slogan, promo code, for example: spring_sale</small></p>
                    </div>

                    <div class="col-xs-12 col-sm-6 col-md-6 mt-2 ">
                        <div class="form-floating">
                            <select class="form-select" formControlName="utmMediumCtrl"
                                [ngClass]="{ 'is-invalid':formUpdate.utmMediumCtrl.errors && submitted}"
                                (ngModelChange)="generateUtmID($event)">
                                <option value="" selected>Select UTM medium</option>
                                <option *ngFor="let utmMedium of utmMediums" value="{{utmMedium.id}}">
                                    {{utmMedium.utm_medium_name}}
                                </option>
                            </select>
                            <label for="inputTipoCanal">UTM medium</label>
                            <div *ngIf="formUpdate.utmMediumCtrl.errors?.required" class="invalid-feedback">
                                UTM medium is required.
                            </div>
                        </div>
                        <p class="text-muted"><small>Medium is the type of channel.</small></p>
                    </div>


                    <div class="col-xs-12 col-sm-6 col-md-6 mt-2 ">
                        <fieldset disabled>
                            <div class="form-floating">
                                <input type="text" class="form-control" id="floatingInput" placeholder="UTM campaign"
                                    formControlName="utmIdCtrl"
                                    [ngClass]="{ 'is-invalid':formUpdate.utmIdCtrl.errors && submitted}">
                                <label for="floatingInput">UTM ID</label>
                            </div>

                        </fieldset>
                        <p class="text-muted"><small>Used to identify a specific campaign or promotion.</small></p>

                    </div>

                    <div class="col-xs-12 col-sm-6 col-md-6 mt-2 ">
                        <div class="form-floating">
                            <select class="form-select" formControlName="sourcePlatformCtrl"
                                [ngClass]="{ 'is-invalid':formUpdate.sourcePlatformCtrl.errors && submitted}">
                                <option value="" selected>Select Source Platform</option>
                                <option *ngFor="let utmSource of sourcePlatform" value="{{utmSource.id}}">
                                    {{utmSource.utm_source_name}}
                                </option>
                            </select>
                            <label for="inputTipoCanal">Source platform</label>
                            <div *ngIf="formUpdate.sourcePlatformCtrl.errors?.required" class="invalid-feedback">
                                Source platform is required.
                            </div>
                        </div>
                        <p class="text-muted"><small> The platform responsible for directing traffic to a given
                                Analytics
                                property.</small></p>
                    </div>

                    <div class="col-xs-12 col-sm-6 col-md-6 mt-2 ">
                        <div class="form-floating">
                            <select class="form-select" formControlName="utmContentCtrl"
                                [ngClass]="{ 'is-invalid':formUpdate.utmContentCtrl.errors && submitted}">
                                <option value="" selected>Select UTM Content</option>
                                <option *ngFor="let utmContent of utmContents" value="{{utmContent.id}}">
                                    {{utmContent.utm_content_name}}
                                </option>
                            </select>
                            <label for="inputTipoCanal">UTM content</label>
                            <div *ngIf="formUpdate.utmContentCtrl.errors?.required" class="invalid-feedback">
                                UTM content is required.
                            </div>
                        </div>
                        <p class="text-muted"><small>This UTM is useful for distinguishing creatives between the same
                                campaign.
                            </small></p>
                    </div>

                </div>
                <br>
                <div class="col-xs-12 col-sm-12 col-md-12 mt-2">
                    <div class="row">
                        <div class="col-4 p-2 text-end " style="width: 90px;">
                            <button mat-button matStepperPrevious class="btn btn-dark ">Back</button>
                        </div>
                        <div class="col-4 p-2 text-center " style="width: 90px;">
                            <button mat-button class="btn btn-dark " (click)="generateUtm()">Generate UTM</button>
                        </div>
                        <div class="col-4 p-2 text-start " style="width: 90px;">
                            <button mat-button class="btn btn-dark"
                                (click)="formUpdate.utmGeneratedCtrl.value === '' ? generateUtm() : ''; updateCampaign()">Update</button>
                        </div>
                    </div>
                </div>
            </form>

        </mat-step>
    </mat-horizontal-stepper>

    <div class="container" [hidden]="!hiddenUtm">
        <form [formGroup]="campaignUpdateForm">

            <div class="form-floating">
                <div class="textarea-toolbar">
                    <div
                        class="d-flex align-items-center highlight-toolbar ps-3 pe-2 py-1 border-0 border-top border-bottom">
                        <label for="floatingTextarea">UTM</label>
                        <div class="d-flex ms-auto">
                            <button type="button" class="btn btn-light" (click)="copyToClipboard()"><i
                                    class="bi bi-clipboard"></i> Copy</button>
                        </div>
                    </div>
                </div>
                <fieldset disabled>
                    <textarea class="form-control" id="floatingTextarea" formControlName="utmGeneratedCtrl"
                        style="height: 100px"></textarea>

                </fieldset>
            </div>
        </form>
    </div>
</div>