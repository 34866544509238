import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GeneralServiceMessages } from 'src/app/services/messages-services/utilities-message';
import { ngxLoadingAnimationTypes } from 'ngx-loading';
import { BehaviorSubject } from 'rxjs';
import { AuthSerices } from 'src/app/services/auth-services/auth-api.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  public loading = false;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;

  public resetForm!: FormGroup;
  public isSubmitted = false;
  public loggedIn = new BehaviorSubject<boolean>(false);
  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private authSerices : AuthSerices,
    private messageSwal: GeneralServiceMessages
  ) { }

  ngOnInit(): void {
    this.resetForm = this.formBuilder.group({
      email: ['', Validators.required],
    },);
  }
  get formControls() {
    return this.resetForm.controls;
  }

  resetpassword() {
    this.isSubmitted = true;

    if (this.resetForm.invalid) {
      return;
    }
    this.loading = true;
    const email = this.resetForm.controls.email.value;
    const action = 'resetPassword';
    this.authSerices.validateStatusEmailChangePassword({ email, action }).subscribe(() => {
      this.loading = false;
      this.messageSwal.opensweetalertWithThen('Sent successfully ' + email, () => { this.router.navigate(['/login']); });
    }, (err) => {
      this.loading = false;
      const message = typeof err?.error?.status === 'string' ? err?.error?.status : 'An unexpected error occurred.';
      this.messageSwal.opensweetalertwrg(message);
    });
  }

}
