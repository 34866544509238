<div class="container">
</div>
<br>
<div class="col-12">
    <br>
    <div class="container">
        <div class="row">
            <div class="col-6">
                <h2 class="h5">Add User</h2>
            </div>
        </div>
    </div>
    <label for="inputExchange">&nbsp;</label><br>
</div>

<div class="col-lg-12">
    <div class="">
        <ngx-loading [show]="loading"
            [config]="{animationType: ngxLoadingAnimationTypes.cubeGrid, primaryColour: '#ffffff', backdropBorderRadius: '13px'}">
        </ngx-loading>
        <div class="grid-body">
            <div class="item-wrapper">
                <div class="row mb-3">
                    <div class="col-md-8 mx-auto">
                        <form class="needs-validation" [formGroup]="userForm" (ngSubmit)="onSubmit()">

                            <div class="form-group row showcase_row_area">
                                <div class="col-md-3 showcase_text_area">
                                    <label for="inputType12">Full name</label>
                                </div>
                                <div class="col-md-9 showcase_content_area">
                                    <input type="text" class="form-control" placeholder="Full name"
                                        formControlName="fullnameCtrl"
                                        [ngClass]="{ 'is-invalid':f.fullnameCtrl.errors && submitted}">
                                    <div *ngIf="f.emailCtrl.errors" class="invalid-feedback">
                                        Full name is required.
                                    </div>
                                </div>
                            </div>

                            <div class="form-group row showcase_row_area">
                                <div class="col-md-3 showcase_text_area">
                                    <label for="inputType12">Email</label>
                                </div>
                                <div class="col-md-9 showcase_content_area">
                                    <input type="email" class="form-control" placeholder="Email"
                                        formControlName="emailCtrl"
                                        [ngClass]="{ 'is-invalid':f.emailCtrl.errors && submitted}">
                                    <div *ngIf="f.emailCtrl.errors" class="invalid-feedback">
                                        Email is required.
                                    </div>
                                </div>
                            </div>


                            <!-- <div class="form-group row showcase_row_area">
                  <div class="col-md-3 showcase_text_area">
                    <label for="inputType1">Profile Photo <p class="labelimage" >(Recommended Min-with 200px)</p></label>
                  </div>
                  <div class="col-md-9 showcase_content_area">
                    <input type="file" class="custom-file-input  form-control" placeholder="File Upload"
                      formControlName="profileimgCtrl" placeholder="upload profile photo"
                      [ngClass]="{ 'is-invalid':f.profileimgCtrl.errors && submitted}" (change)="changeFile($event)">
                    <label class="custom-file-label" for="customFile">{{nameFile}}</label>
                  </div>
                </div> -->
                            <div class="form-group row showcase_row_area">
                                <div class="col-md-3 showcase_text_area">
                                    <label for="inputType1">Profile</label>
                                </div>
                                <div class="col-md-9 showcase_content_area">
                                    <select class="custom-select" formControlName="perfilCtrl"
                                        [ngClass]="{ 'is-invalid':f.perfilCtrl.errors && submitted}">
                                        <option value="">Select profile</option>
                                        <option *ngFor="let rol of roles | async" value={{rol.name}}>
                                            {{rol.name|uppercase}}</option>
                                    </select>
                                    <div *ngIf="f.perfilCtrl.errors" class="invalid-feedback">
                                        Profile is required.
                                    </div>
                                </div>
                            </div>
                            <button type="submit" class="btn btn-sm btn-primary float-right btnSend">Save</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>