<div class="container-div">
    <div class="container-login100 text-center">
        <div class="col-12 text-end">
            <div class="col-6 ">
                <img src="../assets/logo_direcly.png" id="icon" class="login_icon" alt="User Icon" />
                <br>
            </div>
        </div>
        <div class="container">
            <div class="row">
                <ngx-loading [show]="loading"
                    [config]="{animationType: ngxLoadingAnimationTypes.cubeGrid, primaryColour: '#ffffff', backdropBorderRadius: '13px'}">
                </ngx-loading>

                <div class="form-group col-12">
                    <form [formGroup]="resetForm" (ngSubmit)="resetpassword()">
                        <div class="col-12">
                            <div class="form-group input-rounded"
                                [ngClass]="{ 'has-error': isSubmitted && formControls.email.errors }">
                                <input type="email" class="form-control" placeholder="Email" formControlName="email">
                            </div>

                            <div *ngIf="isSubmitted && formControls.email.errors" class="help-block">
                                <div *ngIf="formControls.email.errors.required">Email is required</div>
                            </div>
                        </div>
                        <div class="form-inline"></div>
                        <button type="submit" class="btn btn-primary btn-block btnNet">Reset Password</button>

                    </form>
                    <a routerLink="/login" class="text-left pt-2 ml-1 white-color"><u>Go to Login</u></a>

                </div>
            </div>
        </div>
    </div>
    <div class="auth_footer mx-auto">
        <p class="text-muted text-center">
        </p>
    </div>
</div>