import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { GeneralServiceMessages } from 'src/app/services/messages-services/utilities-message';
import { AuthSerices } from 'src/app/services/auth-services/auth-api.service';
import { ngxLoadingAnimationTypes } from 'ngx-loading';
import { environment } from 'src/app/environments/environment';

import { BehaviorSubject } from 'rxjs';
const PASSWORD_REGEX = environment.regularExpPass;


@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.css']
})
export class NewPasswordComponent implements OnInit {

  public loading = false;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public newPassForm!: FormGroup;
  public resetPassForm!: FormGroup;
  public isSubmitted = false;
  public loggedIn = new BehaviorSubject<boolean>(false);
  public code: any;
  public token: any;
  public viewer!: string;
  public textValidate = 'Validate email.';
  constructor(  private router: Router,
    private activatedRoute: ActivatedRoute,
    private userApiService: AuthSerices,
    private messageSwal: GeneralServiceMessages,
   ) {
    this.activatedRoute.queryParams.subscribe(params => {
      const mode = params['mode'];
      const token = params['apiKey'];
      if (mode && token) {
        if (mode === 'resetPassword') {
          this.viewer = 'resetPassword';
          this.token = token;
          this.userApiService.verifyStatusLink({ token:token, viewer: this.viewer }).subscribe(() => {

          }, (error) => {
            const message = typeof error?.error?.statusLink === 'string' ? error?.error?.statusLink : 'An unexpected error occurred.';
            this.messageSwal.openSweetAlertWarningWithThen(message, () => { this.router.navigate(['/login']); });
          })

        } else if (mode === 'verifyEmail') {
          this.viewer = 'verifyEmail';
          this.token = token;
          this.userApiService.verifyStatusLink({ token, viewer: this.viewer }).subscribe(() => {
            this.messageSwal.opensweetalertWithThen('Your account has been verified.', () => { this.textValidate = 'Your account has been verified.'; });
          }, (error) => {
            const message = typeof error?.error?.status === 'string' ? error?.error?.status : 'An unexpected error occurred.';
            this.messageSwal.openSweetAlertWarningWithThen(message, () => { this.router.navigate(['/login']); });
          })
        }
      } else {
        this.router.navigate(['/login']);
      }
    });
   }
  

  ngOnInit(): void {
    this.newPassForm = new FormGroup({
      password: new FormControl('', [Validators.required, Validators.pattern(PASSWORD_REGEX)]),
      repeatPassword: new FormControl('', [Validators.required, Validators.pattern(PASSWORD_REGEX)])
    });
    this.resetPassForm = new FormGroup({
      passwordReset: new FormControl('', [Validators.required, Validators.pattern(PASSWORD_REGEX)]),
      repeatPasswordReset: new FormControl('', [Validators.required, Validators.pattern(PASSWORD_REGEX)]),
      email: new FormControl('', [Validators.required])
    });
  }
  get formControls() {
    return this.newPassForm.controls;
  }

  get formControlsReset() {
    return this.resetPassForm.controls;
  }

  newPassword() {
    this.isSubmitted = true;
    if (this.newPassForm.invalid) {
      return;
    }
    if (this.newPassForm.controls.password.value !== this.newPassForm.controls.repeatPassword.value) {
      this.messageSwal.opensweetalertwrg('New Password and Confirm Password do not match');
      return;
    }
    this.loading = true;
    const userUpdate = {
      token: this.token,
      viewer: this.viewer,
      newPassword: this.newPassForm.controls.password.value
    };
    this.userApiService.resetPassword(userUpdate).subscribe(() => {
      this.loading = false;
      this.messageSwal.opensweetalertWithThen('Password successfully changed', () => { this.router.navigate(['/login']); });
    }, (error) => {
      this.loading = false;
      const message = typeof error?.error?.status === 'string' ? error?.error?.status : 'An unexpected error occurred.';
      this.messageSwal.openSweetAlertError(message);
    });
  }

  resetPassword() {
    this.isSubmitted = true;
    if (this.resetPassForm.invalid) {
      return;
    }
    if (this.resetPassForm.controls.passwordReset.value !== this.resetPassForm.controls.repeatPasswordReset.value) {
      this.messageSwal.opensweetalertwrg('New Password and Confirm Password do not match');
      return;
    }
    this.loading = true;
    const userUpdate = {
      token: this.token,
      viewer: this.viewer,
      newPassword: this.resetPassForm.controls.passwordReset.value,
      email: this.resetPassForm.controls.email.value
    };
    this.userApiService.verifyEmail(userUpdate).subscribe(() => {
      this.loading = true;
      this.messageSwal.opensweetalertWithThen('Password successfully changed', () => { this.router.navigate(['/login']); });
    }, (error) => {
      this.loading = false;
      const message = typeof error?.error?.status === 'string' ? error?.error?.status : 'An unexpected error occurred.';
      this.messageSwal.openSweetAlertError(message);
    });
  }

}
