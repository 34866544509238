import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ngxLoadingAnimationTypes } from 'ngx-loading';
import { AuthSerices } from 'src/app/services/auth-services/auth-api.service';
import { GeneralServiceMessages } from 'src/app/services/messages-services/utilities-message';
import { map } from 'rxjs/operators';


@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.css']
})
export class UserEditComponent implements OnInit {
  public userForm!: FormGroup;
  public roles!: Promise<any[]>;
  public submitted = false;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loading = false;
  public state$!: Observable<object>;
  public objtmp: any;

  constructor(
    private formBuilder: FormBuilder,
    private userService: AuthSerices,
    private messageSwal: GeneralServiceMessages,
    private router: Router,
    public activatedRoute: ActivatedRoute,

  ) {
    const rolesArray =
      [{ name: 'administrator' }, { name: 'operation' }, { name: 'generator' }, { name: 'media_plan' }];

    this.roles = Promise.resolve(rolesArray);

    this.state$ = this.activatedRoute.paramMap.pipe(
      map(() => window.history.state)
    )
    this.state$.subscribe(data => {
      this.objtmp = data;
    });
  }

  ngOnInit(): void {
    this.userForm = this.formBuilder.group({
      fullnameCtrl: ['', Validators.required],
      emailCtrl: ['', Validators.required],
      perfilCtrl: ['', Validators.required],
    });
    this.toAssignValuesForm();
  }

  get f() {
    return this.userForm.controls;
  }
  async onSubmit() {

    this.submitted = true;
    if (this.userForm.invalid) {
      return;
    } else {
      this.loading = true;
      let id=this.objtmp.user.id;
      let email = this.userForm.controls.emailCtrl.value || '';
      let fullname = this.userForm.controls.fullnameCtrl.value || '';
      let perfil = this.userForm.controls.perfilCtrl.value || '';
      this.userService.updateUser(id,email, fullname, perfil).subscribe(response => {
        this.router.navigateByUrl('/dashboard/users')
        this.messageSwal.opensweetalert('User successful update');
      }, error => {
        this.loading = false;
        this.messageSwal.openSweetAlertError(error);
      });
    }
  }
  async toAssignValuesForm() {
    this.userForm.controls['fullnameCtrl'].setValue(this.objtmp.user.fullname);
    this.userForm.controls['emailCtrl'].setValue(this.objtmp.user.email);
    this.userForm.controls['perfilCtrl'].setValue(this.objtmp.user.perfil);
  }

}
